import React from "react";
import styled from "styled-components";

const AboutDetails = () => {
  return (
    <AboutDetailsStyle>
      <div className="container">
        <p>
          Peakward Enterprises (Holdings) Ltd. as parent company of Peakward
          Group was incorporated in 1990 in Hong Kong to conduct international
          trading business with an initial focus on dry bulk commodity like
          Cement & Fertilizer. The group started its operation with exporting
          Chinese and Indonesian bagged cement to Bangladesh where it occupied
          30% market share. Gradually sourcing was expanded to Korea, Malaysia,
          Vietnam, Thailand, Indonesia, India, Oman, Taiwan, Turkey, UAE and
          Egypt. At present Peakward has over 25 years of experience in
          international trading and has offices in Hong Kong, China, Vietnam,
          India, Indonesia, Turkey, UAE and Bangladesh. With these offices, and
          the wide range of associates and contacts the group has today expanded
          its operation many folds and achieved sales of more than 10 million
          tons of various commodities. So far the group sold and delivered
          various cargo to Bangladesh, India, Srilanka, Vietnam, Taiwan,
          Ecuador, Yemen, Qatar, UAE, Egypt and Turkey. The Group handles
          various products like Cement (Bagged & Bulk), Clinker, GBF Slag,
          Gypsum (Natural & Chemical), Lime Stone, Natural Pozzolona, Fly Ash,
          Stone & Aggregates, Iron Ore, Steam Coal & Other Bulk Commodities. It
          also handled shipment of Fertilizer, Rock Phosphate, Chemicals, Food
          Grain, Paper, Hard Coke, Machineries and etc. A firm grip on vessel
          unloading logistics is the key element of doing successful large
          volume business.
        </p>
        <p>
          Our group has specific strength in this area. Over the years Peakward
          has introduced many innovative ideas to ensure speedy unloading of
          vessels. Teaming up with local partners Peakward also has developed
          capability of local delivery and has supplied various cargo up to
          buyer's Factory or Storage. In the process we controlled the
          high-speed unloading from the mother vessel to reduce freight and
          improve profitability of business. By controlling vital logistics, we
          can reduce risk and costs and improve profitability. For over 25
          Years, Peakward has established its name in various markets in terms
          of quality product, customer satisfaction and excellence, not only in
          trading but also in Shipping. We ensure timely delivery and
          competitive prices to our customers and move bulk cargo in our own
          chartered vessels under our subsidiary Shipping company which have
          worldwide network of owners/charterers. Peakward's Shipping company
          charters vessels from direct owners on Time or Voyage basis and ensure
          competitive freight on various vessels including Handymax, Panamax,
          Supramax, & Capesize. Being strong in shipping makes Peakward a
          reliable supplier for short & long term contracts. Where fixed price
          is guaranteed for long term quantities and hence buyer have the
          benefit of timely consistent supply at stable price.
        </p>
      </div>
    </AboutDetailsStyle>
  );
};

export default AboutDetails;

const AboutDetailsStyle = styled.article`
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    /* min-height: 100vh; */
    max-width: 1200px;
    margin: auto;
    text-align: justify;
    display: flex;
    justify-content: center;
    gap: 5rem;
    height: fit-content;
    padding-top: 5rem;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 400;
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 1400px) {
    .container {
      max-width: 70%;
      padding: 5rem 0;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      max-width: 90%;
      padding: 5rem 0px;
      flex-direction: column;
      gap: 1rem;
    }
    p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
