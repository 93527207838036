import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import ProductBanner from "../components/ProductBanner";
import ProductDetails from "../components/ProductDetails";
// import ProductShipment from "../components/ProductShipment";
import ProductTable from "../components/ProductTable";
import Products from "../components/Products";
import ProductImage1 from "../assets/img/products_details/clinker.png";
import ProductImage2 from "../assets/img/products_details/limestone.png";
import ProductImage3 from "../assets/img/products_details/gypsum.png";
import ProductImage4 from "../assets/img/products_details/pozzolana.png";
import ProductImage5 from "../assets/img/products_details/slag.png";
import ProductImage6 from "../assets/img/products_details/stone.png";
import quarry1 from "../assets/img/quarry/clinker.jpg";
import quarry2 from "../assets/img/quarry/limestone.jpg";
import quarry3 from "../assets/img/quarry/gypsum.jpg";
import quarry4 from "../assets/img/quarry/pozzolana.jpg";
import quarry5 from "../assets/img/quarry/slag.jpg";
import quarry6 from "../assets/img/quarry/construction_stone.jpeg";
import Map from "../components/Map";
import poster from "../assets/img/pozzolana/poster.png";
import { Brochure } from "../components/Brochure";

const Product = () => {
  const location = useLocation().pathname;

  const [bg, setBg] = useState();
  const [title, setTitle] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    if (location === "/clinker") {
      setBg(ProductImage1);
      setTitle("Clinker");
      setBackgroundImage(quarry1);
    } else if (location === "/limestone") {
      setBg(ProductImage2);
      setTitle("Limestone");
      setBackgroundImage(quarry2);
      // console.log(quarry2);
    } else if (location === "/gypsum") {
      setBg(ProductImage3);
      setTitle("Gypsum");
      setBackgroundImage(quarry3);
    } else if (location === "/pozzolana") {
      setBg(ProductImage4);
      setTitle("Pozzolana");
      setBackgroundImage(quarry4);
    } else if (location === "/slag") {
      setBg(ProductImage5);
      setTitle("Slag");
      setBackgroundImage(quarry5);
    } else if (location === "/construction_stone") {
      setBg(ProductImage6);
      setTitle("Construction Stone");
      setBackgroundImage(quarry6);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let description = "";

  switch (title) {
    case "Clinker":
      description = `Since inception Peakward group has focused in Cement / Clinker as its
          main Business. Peakward group now is a one of the market leader in
          supplying Clinker to South Asia and Gulf region. Our clinker is known
          for premium quality and reliability which ensure maximum value to all
          clients. Our Clinker is inspected and tested at load port by
          independent and internationally recognized inspection agencies such as
          SGS, Intertek and other reputed inspection company. Our Cement
          material expert assist our customer to identify the specific product
          to their needs and/or to local/International standard requirements.
          Peakward developed close relationship with major Cement Clinker
          producers in the world to trade and supply millions tons of cement and
          clinker over the years. We ship the clinker in our own chartered
          vessel for timely delivery at competitive prices. We can ship clinker
          in Handysize, handymax, Panamax, Supramax & Capesize vessels. We have
          huge amount of clinker available from various factories worldwide,
          based on spot and long term contractual requirement. We are exploring
          new market avenues for our branded cement, bulk cement and clinker in
          whole world markets.`;
      break;

    case "Gypsum":
      description = `Supply of Gypsum is one of the core business of Peakward since inception. Peakward can offer natural Gypsum and Chemical Gypsum (Phospho-Gypsum) with different grade of CaSO4.2H2O (87% to 93 %) and natural anhydrite with similar grades. Gypsum is used as setting regulator in cement manufacturing process. We have also Gypsum with high grade characterized by high whiteness for white cement production. Natural Gypsum can be found from mining sedimentary rocks whereas Chemical Gypsum can be found in by-products of chemical industry. Peakward have strategic relationships with mine owners and manufacturers around the world to supply huge quantity of Natural Gypsum and Chemical Gypsum (Phospho-Gypsum) of various
      purities on regular basis.`;
      break;

    case "Limestone":
      description = `Peakward is one of the largest supplier of Limestone in Asian region, with products delivered nationally and internationally, both directly to the customer via global network of subsidiaries. Peakward now is a remarkable & reputed Limestone Trader, Exporter & Supplier for professional service and quality products. Peakward supplying huge quantity of Limestone from Vietnam, China and Oman to Bangladesh on regular basis. Peakward can supply Construction Grade Size: (2-5mm, 5-10mm, 10-20mm) Limestone aggregate as per buyer’s requirements. Origin Oman, China, Indonesia, Thailand, Turkey or Seller’s/Buyer’s Choice.
      Packing can be arranged based on: Bulk in vessels.`;
      break;

    case "Pozzolana":
      description = `Natural Pozzolana is a very good replacement of expensive raw materials of Cement like Slag/Clinker/Limestone and Fly Ash. It is very useful in bringing down your cost of production and hence maximizes profits without compromising on quality. The cement produced with our Natural Pozzolona is very high in Strength and results in faster setting time. It is already very fine in nature and can be directly mixed with Clinker for cement making and also with cement for making concrete.
      The benefits of pozzolan utilization in cement and ready-mix concrete are the economic gain obtained by replacing a substantial part of the Portland cement by cheaper natural pozzolans, the lowering greenhouse gases emitted per ton of blended cement produced in compare to Portland cement.
      The Natural Pozzolona supplied by us is a very high quality product collected from the Volcanic eruptions worldwide and sourced at the site. This high quality Pozzolona is rich in Silica and very good for cement & concrete.`;
      break;

    case "Slag":
      description = `Blast-furnace slag, a by-product of iron and steel industry, is mixed with cement for use as a cement alternative additives. It is an eco-friendly product, which enables reduced energy use and CO2 emissions in its production process by around 40% as compared to producing concrete from cement only, in addition to saving natural resources such as cement raw materials and limestone. Iron and steel manufacturers produce high-quality iron and steel with blast furnaces and a certain volume of blast-furnace slag as by product is generated in the process. BFS is used to make durable concrete structures in combination with ordinary Portland cement and/or other pozzolanic materials.`;
      break;

    default:
      description = `Peakward is supplying high quality crushed stone/aggregates to the construction industry. We have the capability to supply different sizes of aggregates, rocks and rock armor as per specific specifications and quantity requirements. Our Gabbro aggregates are particularly very popular in the construction industry.`;
      break;
  }

  return (
    <>
      <ProductBanner bgImage={bg} title={title} des={description} />
      <ProductDetails title={title} />
      <ProductTable />
      <Cargo>
        <div className="container">
          <p>*We can supply special cargo as per the buyer's need.</p>
        </div>
      </Cargo>
      <Quarry backgroundImage={backgroundImage}>
        <div className="container"></div>
      </Quarry>
      <ProductTypes>
        {title === "Clinker" && (
          <div className="container">
            <p style={{ textAlign: "center", marginBottom: "50px" }}>
              Peakward Group has availability to supply of Grey, White and
              Special Clinker conforming to EN and ASTM standard specifications.
              Peakward Trade & supply various kinds of clinker suitable for
              manufacturing cement according to ASTM, BS and European Standard.
              Our products are acceptable by world-class cement manufacturers.
              Based on our long experience in international trading, we provide
              you excellent services and punctual logistic.
            </p>
            <div>
              <p>
                <span>Types of Clinker:</span>
              </p>
              <ul>
                <li>
                  Grey Clinker which is suitable for the production of cement
                  type Cem I 42.5 N, Cem I 42.5 R, Cem I 52.5 N.
                </li>
                <li>Ordinary Portland Cement (OPC)</li>
                <li>Sulphate Resistant Cement (SRC)</li>
                <li>
                  White Clinker Type I Suitable to produce White Portland Cement
                </li>
              </ul>
            </div>
          </div>
        )}
        {title === "Limestone" && (
          <div className="container">
            <p style={{ textAlign: "center", marginBottom: "50px" }}>
              Peakward Group is a trusted supplier of high-quality limestone,
              catering to various industries and applications. Our limestone
              products are sourced from reputable quarries and adhere to
              stringent quality standards. With extensive experience in
              international trade, we ensure reliable supply, excellent service,
              and efficient logistics.
            </p>
            <div>
              <p>Our range of limestone products includes:</p>
              <ul>
                <li>
                  <span>Construction-Grade Limestone: </span>Ideal for
                  construction projects, our construction-grade limestone is
                  used in applications such as road base, building foundations,
                  and concrete production. It boasts excellent strength and
                  durability, meeting the required specifications for reliable
                  performance.
                </li>
                <li>
                  <span>Agricultural Limestone: </span>Our agricultural
                  limestone, commonly known as aglime, plays a vital role in
                  optimizing soil pH levels and enhancing crop productivity. It
                  effectively neutralizes acidity, improves nutrient
                  availability, and promotes healthy plant growth, resulting in
                  increased yields.
                </li>
                <li>
                  <span>Industrial-Grade Limestone: </span>Our industrial-grade
                  limestone is utilized in diverse manufacturing sectors. It
                  serves as a key component in industries such as glass, steel,
                  chemical, and pharmaceutical, contributing to the production
                  of high-quality end products. Its chemical composition and
                  physical properties make it suitable for various industrial
                  processes.
                </li>
              </ul>
            </div>
          </div>
        )}
        {title === "Gypsum" && (
          <div className="container">
            <div className="cards">
              <div className="card">
                <h1>Premium Gypsum</h1>
                <p>
                  Our Oman Gypsum is sourced from high- quality reserves, known
                  for their purity, superior physical properties, and chemical
                  composition.
                </p>
              </div>

              <div className="card">
                <h1>Extensive Experience</h1>
                <p>
                  With a decade of successful gypsum supply, we bring unmatched
                  expertise and market insights to meet your specific
                  requirements.
                </p>
              </div>

              <div className="card">
                <h1>Stringent Quality Control</h1>
                <p>
                  We adhere to strict quality control measures throughout the
                  production process to gypsum loading in Vessels.
                </p>
              </div>

              <div className="card">
                <h1>Asian and African Markets</h1>
                <p>
                  Our strong presence in these regions ensures seamless delivery
                  and a deep understanding of local demands.
                </p>
              </div>

              <div className="card">
                <h1>Customized Solutions</h1>
                <p>
                  We understand that each customer has unique requirements, and
                  we offer tailored gypsum solutions to meet your specific
                  needs.
                </p>
              </div>

              <div className="card">
                <h1>Supramax and Panamax Shipments</h1>
                <p>
                  Peakward has completed several shipments on supramax size
                  vessels, and we proudly hold the distinction of being the
                  first to load a Panamax vessel from Oman in 2020.
                </p>
              </div>
            </div>
          </div>
        )}
        {title === "Pozzolana" && (
          <div className="container">
            <p style={{ textAlign: "center", marginBottom: "50px" }}>
              Peakward Group is a trusted supplier of high-quality pozzolana,
              serving a wide range of industries and applications. Our pozzolana
              products are carefully sourced and meet stringent quality
              standards. With our extensive experience in international trade,
              we ensure reliable supply, exceptional service, and efficient
              logistics.
            </p>
            <div>
              <p>Our pozzolana offerings include:</p>
              <ul>
                <li>
                  <span>Construction-Grade Pozzolana: </span>Our
                  construction-grade pozzolana is widely used in the
                  construction industry for various applications. It serves as a
                  key ingredient in the production of cement, concrete, and
                  other construction materials. With its unique properties,
                  including enhanced strength and durability, our
                  construction-grade pozzolana contributes to the creation of
                  robust and sustainable structures.
                </li>
                <li>
                  <span>Agricultural Pozzolana: </span>We provide agricultural
                  pozzolana, which is beneficial for soil amendment and crop
                  production. Our agricultural pozzolana helps improve soil
                  fertility, moisture retention, and nutrient availability. It
                  promotes healthy plant growth and increases agricultural
                  yields, contributing to sustainable farming practices.
                </li>
                <li>
                  <span>Industrial-Grade Pozzolana: </span>Our industrial-grade
                  pozzolana finds applications in diverse industries, including
                  cement manufacturing, ceramics, and chemical production. It is
                  utilized as a valuable raw material in the production of
                  blended cements, ceramics with improved strength and
                  durability, and in various chemical processes. Our
                  industrial-grade pozzolana meets strict quality
                  specifications, ensuring optimal performance in industrial
                  applications.
                </li>
              </ul>
            </div>
          </div>
        )}
        {title === "Slag" && (
          <div className="container">
            <div style={{ textAlign: "center", marginBottom: "50px" }}>
              <p>
                <span>Application:</span>
              </p>
              <p>
                Granulated Blast Furnace Slag has a glassy structure and can be
                used as a fine aggregate or binder. GBFS is commonly used in the
                manufacture of blended cements and the ready-mix concrete plant
                where it is inter-ground or blended separately with cement
                usually at a 20% - 40% proportion. Unblended GBFS is can be used
                in road pavements and building products.
              </p>
            </div>
            <div>
              <p>
                <span>Packing:</span>
              </p>
              <p>Packing can be arranged based on:</p>
              <ul>
                <li>Bulk in vessels (GBFS)</li>
                <li>In 50kg bags in 1-2MT Sling Bags or Buyers option</li>
                <li>In Bulk (Big Bags) of 1-2MT Sling Bags</li>
              </ul>
              <p>
                All shipment compartments will be sealed and waterproofed on
                board vessels.
              </p>
            </div>
          </div>
        )}
        {title === "Construction Stone" && (
          <div className="container">
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              Peakward Group, one of the leading Gabbro Aggregate supplier of
              Asian region. Now Peakward is focusing on Crushed Stone /
              Aggregates as one of the core business and committed to offer high
              quality crushed aggregate / stone to the construction industry.
            </p>
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              Also Peakward offer a wide range of products such as rock armour
              for use in land reclamation projects, all sizes of aggregate for
              use in the production of ready mixed concrete and asphalt down to
              road base and infill materials.
            </p>
            <p style={{ textAlign: "center", marginBottom: "20px" }}>
              Peakward has the capability to supply different sizes of
              aggregates, rocks and rock armour as per specific specifications
              and quantity requirements.
            </p>

            <div>
              <p>
                <span>Construction Aggregate sizes are as follows:</span>
              </p>
              <ul>
                <li>Gabbro (Sand) 0-5 mm</li>
                <li>Gabbro 5-10 mm</li>
                <li>Gabbro 10-20 mm</li>
                <li>Gabbro 20-30 mm</li>
                <li>Gabbro 20-40 mm</li>
                <li>All Sizes of Rock and Rock Armour.</li>
              </ul>
            </div>
          </div>
        )}
      </ProductTypes>
      {title === "Pozzolana" && (
        <Poster>
          <div className="container">
            <div className="wrapper">
              <div>
                <h1>Go Green</h1>
                <p>Save the world for a better future</p>
              </div>
              <img src={poster} alt="poster" />
            </div>
          </div>
        </Poster>
      )}
      <Map title={title} />
      {/* <ProductShipment /> */}
      <Brochure />
      <Products bg={"#D9E2FE"} />
    </>
  );
};

export default Product;

const Cargo = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  .container {
    text-align: center;
    background-color: ${(props) => props.theme.colors.primary};
    margin: auto;
    padding: 3rem;
  }

  .container > p {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    color: ${(props) => props.theme.colors.default};
    font-weight: 300;
  }
  @media only screen and (max-width: 600px) {
    .container > p {
      font-size: ${(props) => props.theme.fontSizes.h6};
      line-height: ${(props) => props.theme.lineHeights.h6};
    }
  }
`;

const Quarry = styled.div`
  .container {
    overflow: hidden;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 90vh;
  }

  @media only screen and (max-width: 600px) {
    .container {
      height: 30vh;
    }
  }
`;

const ProductTypes = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  .container {
    max-width: 1000px;
    margin: auto;
    padding: 8rem 0;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .container p,
  .container ul li {
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .container ul {
    margin-left: 20px;
  }

  span {
    font-weight: 700;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .card {
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-height: 285px;
  }

  .card h1 {
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-family: "Open Sans", sans-serif;
    color: ${(props) => props.theme.colors.default};
  }

  .card p {
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-family: "Open Sans", sans-serif;
    color: ${(props) => props.theme.colors.default};
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 8rem 1rem;
    }
    .container p,
    .container ul li {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }

    .cards {
      grid-template-columns: 1fr;
      gap: 1rem;
    }

    .card {
      gap: 1rem;
      min-height: 250px;
    }

    .card h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }
  }
`;

const Poster = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: auto;
  .container {
    width: 100%;
    background-color: #e8feeb;
  }
  .wrapper {
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #e8feeb;
    position: relative;
    color: #166426;
  }

  .wrapper div {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 5rem;
  }

  .wrapper h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.xl};
    line-height: ${(props) => props.theme.lineHeights.xl};
    font-weight: 700;
  }
  .wrapper p {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    font-weight: 400;
  }

  img {
    max-width: 520px;
    position: absolute;
    right: 0;
  }

  @media only screen and (max-width: 600px) {
    .wrapper {
      flex-direction: column;
    }
    .wrapper div {
      max-width: 500px;

      row-gap: 1rem;
      padding: 2rem;
    }
    .wrapper h1 {
      font-size: var(--font-h5);
      line-height: var(--height-h5);
    }

    .wrapper p {
      font-size: var(--font-h6);
      line-height: var(--height-h6);
    }

    img {
      width: 100%;
      position: unset;
    }
  }
`;
