import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BannerImage from "../assets/img/banner.png";
// import Video1 from "../assets/video/ship1.mp4";
// import Video2 from "../assets/video/ship2.mp4";
// import Video3 from "../assets/video/ship3.mp4";
// import Video4 from "../assets/video/ship4.mp4";
// import Video5 from "../assets/video/ship5.mp4";
// import Video6 from "../assets/video/ship6.mp4";
import Video7 from "../assets/video/ship7.mp4";

const HomeBanner = ({ setSellerModal }) => {
  return (
    <Banner>
      <div className="overlay"></div>
      <video
        src={Video7}
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          position: "absolute",
          right: 0,
          transform: "rotateY(180deg)",
          WebkitTransform: "rotateY(180deg)" /* Safari and Chrome */,
          MozTransform: "rotateY(180deg)" /* Firefox */,
        }}
      />
      <div className="container">
        <article className="text">
          <h1>Pioneering Excellence in Trading Since 1990</h1>
          <div>
            <p>
              Guiding global development with sustainable, superior quality
              materials, and fueling the future with unmatched global supply
              chain solutions.
            </p>
            <button>
              <Link
                to="/about"
                style={{ color: "#ffffff", textDecoration: "none" }}
              >
                <p>learn more</p>
              </Link>
              <span>
                <FontAwesomeIcon
                  icon={faArrowRightLong}
                  style={{ color: "#ffffff" }}
                />
              </span>
            </button>
          </div>
        </article>
      </div>
      <div className="supplier">
        <h1>Are you a buyer?</h1>
        <button onClick={() => setSellerModal(true)}>GET IN TOUCH</button>
      </div>
    </Banner>
  );
};

export default HomeBanner;

const Banner = styled.div`
  min-height: 100vh;
  /* background-image: url(${BannerImage}); */

  background-size: cover;
  z-index: 1;
  position: relative;
  .overlay {
    background: ${(props) => props.theme.colors.linear};
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  video::-webkit-media-controls {
    display: none !important;
  }

  .container {
    min-height: 100vh;
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .text {
    z-index: 3;
    width: 50%;
    color: ${(props) => props.theme.colors.default};
    font-family: "Josefin Sans", sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .text h1 {
    font-size: ${(props) => props.theme.fontSizes.h1};
    line-height: ${(props) => props.theme.lineHeights.h1};
    font-weight: 600;
  }

  .text p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 300;
  }

  button {
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.colors.default};
    width: fit-content;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-family: "Josefin Sans", sans-serif;
    margin-top: 20px;
  }

  button p {
    font-size: ${(props) => props.theme.fontSizes.h5} !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    transition: all 0.5s ease;
    &:hover {
      padding-right: 1rem;
    }
  }

  button span {
    font-size: ${(props) => props.theme.fontSizes.h5};
  }

  .supplier {
    position: absolute;
    bottom: -55px;
    right: 0px;
    left: 0px;
    background-color: ${(props) => props.theme.colors.primary};
    max-width: 700px;
    margin: auto;
    z-index: 100;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    color: ${(props) => props.theme.colors.default};
    padding: 1.5rem 4rem;
  }

  .supplier h1 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    font-family: "Open Sans";
    font-weight: 700;
    font-size: 32px;
  }

  .supplier button {
    background-color: ${(props) => props.theme.colors.default};
    color: ${(props) => props.theme.colors.primary};
    border: none;
    padding: 1rem 2rem;
    border-radius: 1.5rem;
    font-size: ${(props) => props.theme.fontSizes.h6};
    font-family: "Josefin Sans";
    font-weight: 700;
    display: block;
    cursor: pointer;
    margin-top: 0;
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }

  @media only screen and (max-width: 600px) {
    background-position: right -20rem center;
    .text {
      width: 100%;
      text-align: center;
      padding: 0 1rem;
    }

    .text div {
      display: flex;
      flex-direction: column;
    }
    .container {
      justify-content: center;
      align-items: center;
      padding: 10rem 1rem;
    }
    button {
      align-self: center;
    }

    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
      line-height: 2.2rem;
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.h6} !important;
      line-height: ${(props) => props.theme.lineHeights.p};
      /* padding: 0 1rem; */
      /* text-align: justify; */
    }

    .supplier {
      width: 90%;
      padding: unset;
      gap: 0;
      justify-content: center;
      padding: 1rem 0;
      flex-direction: column;
      gap: 20px;
    }

    .supplier h1 {
      font-size: ${(props) => props.theme.fontSizes.h6};
    }

    .supplier button {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
`;
