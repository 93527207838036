import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import bg from "../assets/img/shipping/bg.jpg";
import Vessel from "../components/Vessel";

const Shipping = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ShippingStyle>
        <div className="overlay"></div>
        <div className="banner">
          <h1>Shipping</h1>
        </div>
        <div className="details">
          <p>
            Peakward have exclusive shipping team which stays updated with great
            zeal of response on freight and price index worldwide to help their
            valuable buyers for supply chain requirements on right time with
            right price. Chinese Holdings (HK) Limited is one of the subsidiary
            shipping companies which is managed by exclusive shipping team of
            Peakward. Peakward Group transports bulk commodities for their
            customers/buyers worldwide. Since 1990, The Group managed and
            handled more than 300 deep-sea bulk carriers using a combination of
            single-voyage charters, short- and medium-term chartered vessels.
            Peakward has long term relationships with various world class ship
            brokers that provide the best freight opportunities for chartering
            vessels under various types of Charter Parties: for spot shipments
            or long-term contact. Through our vast contacts in shipping,
            Peakward is able to move Cement, Clinker and other bulk commodities
            around the world to any port with efficiency and flexibility. CHK
            has been operating voyage and time charter vessels for more than a
            decade with close co-operation with cargo owners and meeting their
            shipping needs with full satisfaction. CHK has a full complement of
            experienced employees capable of handling all kinds of cargo
            shipping needs for its customers. A shipping company dedicated to
            providing close support, CHK assists its customer’s in reaching and
            expanding their trading markets and thereby becomes a partner in
            their global business growth. CHK is particularly strong in dry bulk
            commodity shipping business mainly handling Cement, Clinker, Gypsum,
            BF Slag, Pozzolana, Limestone, Stone & Aggregates, Fly Ash,
            Rock-Phosphate, Coal and Food Grains for selected clients on regular
            basis. CHK controls a diverse and flexible fleet of dry bulk
            carriers to serve major industrial and trading customers worldwide.
            Ranging from Handysize up to Panamax, these carriers are suitable
            for transporting a wide variety of cargoes such as Clinker,
            Limestone, Coal, BF Slag, Cement and Steel products. We operate our
            business reliably and efficiently and offer A1 class solutions &
            services as per our selected customers requirements. With our
            professionalism, long experience and good reputation, we have built
            close relations with many well-established ship owners and
            charterers around the world. CHK is now launching itself to a
            glorious future. We are an exemplary shipping company dedicated to
            give close support to our customers in expanding their trading
            markets and thereby become a partner in their global business
            growth.
          </p>
        </div>
        <Vessel />
      </ShippingStyle>
    </>
  );
};

export default Shipping;

const ShippingStyle = styled.div`
  z-index: 1;
  .banner {
    min-height: 100vh;
    /* background-color: rgba(13, 24, 86, 0.65); */
    background-image: url(${bg});
    background-position: center bottom;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.586);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100vh;
    width: 100%;
    z-index: 2;
  }

  .banner h1 {
    z-index: 3;
    font-size: ${(props) => props.theme.fontSizes.h2};
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.default};
  }

  .details {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }

  .details p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    text-align: justify;
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 600px) {
    .details {
      width: 90%;
      padding: 5rem 0;
    }

    .banner h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
    }

    .details p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
