import React from "react";
import styled from "styled-components";
import Value1 from "../assets/img/about/value1.svg";
import Value2 from "../assets/img/about/value2.svg";
import Value3 from "../assets/img/about/value3.svg";
import Value4 from "../assets/img/about/value4.svg";

const Values = () => {
  return (
    <ValueStyle>
      <div className="container">
        <div className="titles">
          <p>ABOUT US</p>
          <h1>Our Core Values</h1>
          {/* <p>Our business spans globally across 8 countries.</p> */}
        </div>
        <div className="cards">
          <div className="card">
            <img src={Value1} alt="icon" />
            <h1>Partnership</h1>
            <p>
              We uphold the importance of valuing and understanding the needs
              and desires of every partnership.
            </p>
          </div>
          <div className="card">
            <img src={Value2} alt="icon" />
            <h1>Integrity</h1>
            <p>
              At the core of our values lies integrity, which is our most
              essential and trusted asset. Our commitment to our word is
              everything.
            </p>
          </div>
          <div className="card">
            <img src={Value3} alt="icon" />
            <h1>Commitment</h1>
            <p>
              At Peakward, commitment is more than a value; it’s the driving
              force behind our unwavering dedication to delivering exceptional
              results for our clients.
            </p>
          </div>
          <div className="card">
            <img src={Value4} alt="icon" />
            <h1>Teamwork</h1>
            <p>We are a unified team working together to meet common goals.</p>
          </div>
        </div>
        {/* <div className="button">
          <button>Learn More</button>
        </div> */}
      </div>
    </ValueStyle>
  );
};
export default Values;

const ValueStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1600px;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 2rem;
  }

  .titles {
    text-align: center;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: ${(props) => props.theme.colors.primary};
    padding-bottom: 5rem;
  }

  .titles p:first-of-type {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.highlight};
    font-weight: 600;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
  }

  .titles p:last-of-type {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
  }

  .titles h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h4};
    line-height: ${(props) => props.theme.lineHeights.h4};
  }

  .cards {
    display: flex;
    justify-content: space-between;
    column-gap: 3rem;
  }

  .card {
    text-align: center;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: ${(props) => props.theme.colors.primary};
    border: 2px solid ${(props) => props.theme.colors.serviceBorder};
    border-radius: 10px;
    padding: 1.8rem;
  }

  .card img {
    height: 80px;
    padding-bottom: 1rem;
  }

  .card h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h5};
    /* line-height: ${(props) => props.theme.lineHeights.h5}; */
  }

  .card p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
  }

  .button button {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    background: transparent;
    width: fit-content;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: 800;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${(props) => props.theme.colors.btnHover};
      color: ${(props) => props.theme.colors.default};
    }
  }

  @media only screen and (max-width: 1400px) {
    .container {
      padding: 5rem 0;
      max-width: 1200px;
      row-gap: 3rem;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
    }
    .cards {
      flex-direction: column;
      row-gap: 2rem;
    }
    .titles {
      padding-bottom: 0;
    }

    .titles p:first-of-type {
      font-weight: 600;
      font-size: 1.4rem;
      font-size: ${(props) => props.theme.fontSizes.h6};
    }

    .titles p:last-of-type {
      font-family: "Open Sans", sans-serif;
      font-size: ${(props) => props.theme.fontSizes.p};
    }

    .titles h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }
  }
`;
