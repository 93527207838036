import React, { useEffect } from "react";
import ContactBanner from "../components/ContactBanner";
import ContactList from "../components/ContactList";
import ContactForm from "../components/ContactForm";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ContactBanner />
      {/* <ContactList /> */}
      <ContactForm />
    </>
  );
};

export default Contact;
