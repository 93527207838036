import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    
  html {
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  body{
    overflow-x: hidden;
  }

  :root {
    /* colors */
  --primary: #0D1856;
  --secondary: #EBF0FE;
  --highlight: #007AFF;
  --caption:rgba(13, 24, 86, 0.4);
  --default: #ffffff;
  --black:#000000;
  --linear-bg: linear-gradient(106.67deg,#50638d 0%,#0d1856 0.01%,#3d4f8e 37.5%,rgba(92, 114, 179, 0.25) 77.08%, rgba(253, 253, 253, 0) 100%);
  --overlay-bg:#00000071;
  --banner-bg:rgba(13, 24, 86, 0.65);
  --contact-bg:rgba(13, 24, 86, 0.05);
  --border:#7781b8;
  --nav-border:rgba(255, 255, 255, 0.15);
  --btn-hover:#37487a;
  --mobile-menu:rgb(217, 226, 254);
  --mobile-menu-bg:rgb(217 226 254 / 20%);
  --nav:#212f6d;
  --banner-overlay:rgba(0, 0, 0, 0.7);
  --service-border: rgb(188 201 241);
  --service-bg:#e9eefe;

  /* fonts */
  --font-xl: 64px;
  --font-h1: 56px;
  --font-h2: 48px;
  --font-h3: 40px;
  --font-h4: 32px;
  --font-h5: 24px;
  --font-h6: 20px;
  --font-p: 16px;
  --font-small: 14px;

  /* line heights */
  --height-xl: 72px;
  --height-h1: 64px;
  --height-h2: 54px;
  --height-h3: 48px;
  --height-h4: 40px;
  --height-h5: 32px;
  --height-h6: 30px;
  --height-p: 32px;
  --height-small: 26px;
}
/* ======================== */
/* html.has-scroll-smooth {
  overflow: hidden; 
} */

/* 
html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; } */

  /* =============================== */

/* .has-scroll-smooth body {
  overflow: hidden; } */

/* .has-scroll-smooth [data-scroll-container] {
  min-height: 100vh; 
}

[data-scroll-direction="horizontal"] [data-scroll-container] {
  height: 100vh;
  display: inline-block;
  white-space: nowrap; 
}

[data-scroll-direction="horizontal"] [data-scroll-section] {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  height: 100%; }

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0; 
}
  .c-scrollbar:hover {
    transform: scaleX(1.45); 
  }
  .c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
    opacity: 1; 
  }
  [data-scroll-direction="horizontal"] .c-scrollbar {
    width: 100%;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: scaleY(1); 
  }
    [data-scroll-direction="horizontal"] .c-scrollbar:hover {
      transform: scaleY(1.3); 
    }

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab; 
}
  .has-scroll-dragging .c-scrollbar_thumb {
    cursor: -webkit-grabbing;
    cursor: grabbing; 
  }
  [data-scroll-direction="horizontal"] .c-scrollbar_thumb {
    right: auto;
    bottom: 0; 
    } */
`;

export default GlobalStyle;
