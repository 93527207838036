export const theme = {
  colors: {
    primary: "var(--primary)",
    secondary: "var(--secondary)",
    highlight: "var(--highlight)",
    caption: "var(--caption)",
    default: "var(--default)",
    black: "var(--black)",
    linear: "var(--linear-bg)",
    overlay: "var(--overlay-bg)",
    bannerBg: "var(--banner-bg)",
    contactBg: "var(--contact-bg)",
    border: "var(--border)",
    navBorder: "var(--nav-border)",
    btnHover: "var(--btn-hover)",
    mobileMenu: "var( --mobile-menu)",
    mobileMenuBg: "var(--mobile-menu-bg)",
    nav: "var(--nav)",
    bannerOverlay: "var(--banner-overlay)",
    serviceBorder: "var(--service-border)",
    serviceBg: "var(--service-bg)",
  },

  fontSizes: {
    xl: "var(--font-xl)",
    h1: "var(--font-h1)",
    h2: "var(--font-h2)",
    h3: "var(--font-h3)",
    h4: "var(--font-h4)",
    h5: "var(--font-h5)",
    h6: "var(--font-h6)",
    p: "var(--font-p)",
    small: "var(--font-small)",
  },

  lineHeights: {
    xl: "var(--height-xl)",
    h1: "var(--height-h1)",
    h2: "var(--height-h2)",
    h3: "var(--height-h3)",
    h4: "var(--height-h4)",
    h5: "var(--height-h5)",
    h6: "var(--height-h6)",
    p: "var(--height-p)",
    small: "var(--height-small)",
  },
};
