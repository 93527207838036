import Member1 from "../assets/img/team/member1.png";
import Member1Mobile from "../assets/img/team/member1-mobile.png";
import Facebook from "../assets/img/social/facebook.svg";
import Linkedin from "../assets/img/social/linkedin.svg";
import Twitter from "../assets/img/social/twitter.svg";
import Quote from "../assets/img/team/quote.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Team = () => {
  return (
    <TeamStyle className="team">
      <div className="container">
        <div className="titles">
          <h1>Board of Directors</h1>
          {/* <p>
            Mr. Fazle Azim, starting his career as a banker in 1973, spent 17
            years in senior management roles before establishing his own global
            commodity trading company, Peakward Enterprises, in Hong Kong in
            1990. With its corporate social responsibility deeply rooted in Mr.
            Azim's belief in the symbiotic relationship between business and
            society, Peakward continues to innovate within the industry,
            introducing new products and supply sources.
          </p> */}
        </div>
        <div className="members">
          <div className="member">
            {/* <img id="left-quote" src={Quote} alt="left quote" />
            <img id="right-quote" src={Quote} alt="right quote" /> */}
            <div className="left">
              <img src={Member1} alt="team" />
              <img id="member1-mobile" src={Member1Mobile} alt="team" />
            </div>
            <div className="right">
              <div className="info">
                <p>Chairman</p>
                <h1>Fazle Azim</h1>
                {/* <div className="social">
                  <Link
                    to="https://www.linkedin.com/in/fazle-azim-2aa166104/"
                    target="_blank"
                  >
                    <img src={Linkedin} alt="social link" />
                  </Link>{" "}
              
                </div> */}
              </div>
              <div className="quote">
                <p>
                  Mr. Fazle Azim, starting his career as a banker in 1973, spent
                  17 years in senior management roles before establishing his
                  own global commodity trading company, Peakward Enterprises, in
                  Hong Kong in 1990. With its corporate social responsibility
                  deeply rooted in Mr. Azim's belief in the symbiotic
                  relationship between business and society, Peakward continues
                  to innovate within the industry, introducing new products and
                  supply sources.
                </p>
                <br></br>
                <p>
                  PEAKWARD is focused mainly on the trading of Cement industry
                  raw materials (Cement, Clinker, Natural Gypsum, Granulated,
                  and Powder Blast Furnace Slag, Copper Slag, Limestone, Natural
                  Pozzolana) as well as other bulk commodities like Aggregate
                  and all kinds and sizes of Construction Stones, Coal,
                  Fertilisers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TeamStyle>
  );
};

export default Team;

const TeamStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};

  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* min-height: 80vh; */
    text-align: center;
    row-gap: 5rem;
    padding: 5rem 0;
  }

  .titles {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .titles h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h3};
    /* line-height: ${(props) => props.theme.lineHeights.small}; */
    font-family: "Josefin Sans", sans-serif;
  }

  .titles p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    max-width: 1000px;
  }

  .members {
    display: flex;
    column-gap: 5rem;
    justify-content: center;
    align-items: center;
  }

  .members .member {
    border: 4px solid ${(props) => props.theme.colors.primary};
    max-width: 1000px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    /* column-gap: 8rem; */
    padding: 1.2rem;
    position: relative;
    #left-quote {
      transform: rotate(180deg);
      position: absolute;
      top: -10%;
      left: -3%;
    }
    #right-quote {
      position: absolute;
      bottom: -10%;
      right: -3%;
    }
    /* transition: all 0.5s ease; */
    /* &:hover {
      background-color: #ffffff;
      cursor: pointer;
    } */

    .left {
      display: flex;
      align-self: flex-start;
      img {
        width: 100%;
        /* height: 100%; */
      }
      #member1-mobile {
        display: none;
      }
    }

    .right {
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 1.5rem;
    }
  }

  .members .member .info {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .members .member .info h1 {
    font-size: ${(props) => props.theme.fontSizes.h6};
    /* line-height: ${(props) => props.theme.lineHeights.h6}; */
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 600;
  }

  .members .member .info p {
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-family: "Open Sans", sans-serif;
    color: ${(props) => props.theme.colors.highlight};
    font-weight: 400;
  }

  .social {
    display: flex;
    column-gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 0.4rem;
  }

  .social img {
    width: 30px;
  }

  .quote p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-family: "Open Sans", sans-serif;
    line-height: 1.6;
  }

  @media only screen and (max-width: 1600px) {
    .members .member {
      max-width: 60%;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
    }
    .members {
      flex-direction: column;
      row-gap: 10rem;
      .member {
        grid-template-columns: 1fr;
        min-width: 300px;
        width: 100%;
        max-width: unset;
        #left-quote {
          top: -4%;
          left: -9%;
        }
        #right-quote {
          bottom: -4%;
          right: -9%;
        }
        .left {
          img {
            width: 100%;
            height: 50%;
            display: none;
          }
          #member1-mobile {
            display: block;
          }
        }
      }
    }
    .titles h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .titles p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
