import React from "react";
import styled from "styled-components";
import clinkerMap from "../assets/img/clinker/map.svg";
import gypsumMap from "../assets/img/gypsum/map.svg";
import limestoneMap from "../assets/img/limestone/map.svg";
import slagMap from "../assets/img/slag/map.svg";
import pozzolanaMap from "../assets/img/pozzolana/map.svg";

const Map = ({ title }) => {
  return (
    <MapStyle>
      <div className="container">
        {title === "Clinker" && (
          <>
            <h3>Clinker Supply Sources</h3>
            <div className="countries">
              <div className="country">Vietnam</div>
              <div className="country">UAE</div>
              <div className="country">Pakistan</div>
              <div className="country">Thailand</div>
              <div className="country">Indonesia</div>
              <div className="country">KSA</div>
              <div className="country">Oman</div>
            </div>
            <div className="map">
              <img src={clinkerMap} alt="map" />
            </div>
          </>
        )}

        {title === "Gypsum" && (
          <>
            <h3>Gypsum Supply Sources</h3>
            <div className="countries">
              <div className="country">Oman</div>
            </div>
            <div className="map">
              <img src={gypsumMap} alt="map" />
            </div>
          </>
        )}

        {title === "Limestone" && (
          <>
            <h3>Limestone Supply Sources</h3>
            <div className="countries">
              <div className="country">Vietnam</div>
              <div className="country">UAE</div>
              <div className="country">China</div>
              <div className="country">Thailand</div>
              <div className="country">Indonesia</div>
              <div className="country">Turkey</div>
              <div className="country">Oman</div>
            </div>
            <div className="map">
              <img src={limestoneMap} alt="map" />
            </div>
          </>
        )}

        {title === "Slag" && (
          <>
            <h3>Slag Supply Sources</h3>
            <div className="countries">
              <div className="country">Vietnam</div>
              <div className="country">Japan</div>
              <div className="country">Korea</div>
              <div className="country">India</div>
              <div className="country">Indonesia</div>
            </div>
            <div className="map">
              <img src={slagMap} alt="map" />
            </div>
          </>
        )}

        {title === "Construction Stone" && (
          <>
            <h3>Construction Stone Supply Sources</h3>
            <div className="countries">
              <div className="country">Vietnam</div>
              <div className="country">UAE</div>
              <div className="country">China</div>
              <div className="country">Thailand</div>
              <div className="country">Indonesia</div>
              <div className="country">Turkey</div>
              <div className="country">Oman</div>
            </div>
            <div className="map">
              <img src={limestoneMap} alt="map" />
            </div>
          </>
        )}
        {title === "Pozzolana" && (
          <>
            <h3>Natural Pozzolana Supply Sources</h3>
            <div className="countries">
              <div className="country">Indonesia</div>
            </div>
            <div className="map">
              <img src={pozzolanaMap} alt="map" />
            </div>
          </>
        )}
      </div>
    </MapStyle>
  );
};
export default Map;

const MapStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    padding: 5rem 0;
  }

  .container h3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
  }

  .countries {
    display: flex;
    gap: 30px;
  }

  .country {
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 600;
    background-color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    padding: 10px;
    min-width: 100px;
    text-align: center;
  }

  .map img {
    width: 100%;
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
    }
    .countries {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
    .container h3 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
      font-weight: 700;
      text-align: center;
    }
    .country {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};

      padding: 5px;
      min-width: 100px;
    }
  }
`;
