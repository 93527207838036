import React from "react";
import styled from "styled-components";
import cover from "../assets/img/brochure/cover.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import pdf from "../assets/documents/shipping.pdf";
import { Link } from "react-router-dom";

export const Brochure = () => {
  const handleDownload = () => {
    // // Generate or fetch the PDF file URL
    // const pdfUrl = pdf;

    // // Create a virtual link element
    // const link = document.createElement("a");
    // link.href = pdfUrl;
    // link.download = "peakward Corporate Profile 2023.pdf";

    // // Dispatch a click event on the link element
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(`${pdf}`, "_blank");
  };
  return (
    <BrochureStyled>
      <div className="container">
        <div className="cover">{/* <img src={cover} alt="bulk ship" /> */}</div>
        <div className="text">
          <h1>How we do it</h1>
          <div>
            <p>
              At Peakward Group, our approach is governed by our unwavering
              commitment to quality, sustainability, and customer satisfaction.
              Our process begins with stringent sourcing; we select our raw
              materials from trusted suppliers worldwide, ensuring superior
              quality and reliability. Harnessing the power of our efficient
              supply chain management and expansive logistics network, we ensure
              the smooth and timely transportation of goods, from source to
              destination. Our operations are imbued with a deep respect for the
              environment, strictly adhering to international standards and best
              practices for sustainability. Finally, our dedicated customer
              service team is always at the helm, ready to assist and ensure
              seamless transactions for all our clients. This holistic approach
              to business defines the Peakward way, ensuring excellence across
              all our product offerings.
            </p>
            {/* <p>
              Our seasoned teams build personalized relationships with each
              client, crafting the most suitable shipping solutions for every
              type of cargo.
            </p> */}
            {/* <Link to={pdf} target="_blank" rel="noreferrer"> */}
            <button onClick={() => handleDownload()}>
              Download Company Portfolio
              <FontAwesomeIcon icon={faFileArrowDown} />
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </BrochureStyled>
  );
};

const BrochureStyled = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};

  .container {
    display: flex;
    margin: auto;
    gap: 5rem;
    max-width: 1200px;
    padding: 5rem 0;
    align-items: center;
  }
  /* img {
    width: 80%;
   
  } */
  .text {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .text div {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .text h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
  }
  .text p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .text button {
    border: none;
    width: fit-content;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.default};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: ${(props) => props.theme.fontSizes.p};
    border-radius: 20.5px;
    padding: 0.8rem 1.2rem;
    font-weight: 700;
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  a {
    text-decoration: none;
  }
  .cover {
    background-image: url(${cover});
    width: 100%;
    height: 550px;
    background-size: cover;
    background-position: center;
  }

  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      padding: 5rem 1rem;
      gap: 2rem;
    }

    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }
    .text p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }

    .text button {
      align-self: center;
    }

    .cover {
      height: 300px;
    }
  }
`;
