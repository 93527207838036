import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import bg from "../assets/img/trading/bg.jpg";

const Trading = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <TradingStyle>
        <div className="overlay"></div>
        <div className="banner">
          <h1>Trading</h1>
        </div>
        <div className="details">
          <p>
            An exchange of goods or services across global jurisdictions.
            Inbound trade is defined as imports and outbound trade is defined as
            exports. Subject to the regulatory oversight and taxation of the
            involved nations, namely through customs.Peakward’s Trading arm
            focuses mainly on commodities such as Cement, Clinker, Gypsum
            (Natural & Chemical, Limestone, BF Slag, Pozzolana (Volcanic ash)),
            Stone & Aggregates, Fly Ash, Coal, Fertilizer and iron ore that are
            essential in supporting global rapid growth. Peakward's success has
            been strongly influenced by 25 years experience and expertise in
            Cement raw materials and other bulk commodities trading from a
            Local, Regional and International perspective on both the Import and
            Export sides. Peakward have developed extensive experience within
            the Cement and Concrete industries in Middle-East, China, India,
            Thailand, Vietnam, Korea, Taiwan, Indonesia, Bangladesh, Iran,
            Turkey, Egypt and have unique access to a wide range of
            international resources. Taking advantage of its wide trading
            activities in many countries Peakward time to time handled other
            commodities like Fertilizer, Steel, Food Grain,Sugar, Oil Seeds,
            Soda Ash, Newsprint paper etc. Peakward's global trading operation
            is one of the largest and most widely recognized in the industry. It
            is supported by a worldwide network of strategically located sea
            port and strong relationships with reliable manufacturers,
            suppliers/shippers around the world.
          </p>
        </div>
      </TradingStyle>
    </>
  );
};

export default Trading;

const TradingStyle = styled.div`
  z-index: 1;
  .banner {
    min-height: 100vh;
    /* background-color: rgba(13, 24, 86, 0.65); */
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .overlay {
    background: rgba(59, 70, 133, 0.486);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100vh;
    width: 100%;
    z-index: 2;
  }

  .banner h1 {
    z-index: 3;
    font-size: ${(props) => props.theme.fontSizes.h2};
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.default};
  }

  .details {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }

  .details p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    text-align: justify;
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 600px) {
    .details {
      width: 90%;
      padding: 5rem 0;
    }
    .banner h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
    }

    .details p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
