import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/img/logo.png";
import Burger from "../assets/img/burger.svg";
import MenuArrow from "../assets/img/menuArrow.svg";
import { useEffect } from "react";

const safeDocument = typeof document !== "undefined" ? document : {};

const Nav = () => {
  const [activateProducts, setActivateProducts] = useState(false);
  const [activateBusiness, setActivateBusiness] = useState(false);
  const [burgerMenu, setBurgerMenu] = useState(false);
  const [navbar, setNavbar] = useState(false);
  // scroll control
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue("padding-right")
      ) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = "relative"; /* [1] */
    html.style.overflow = "hidden"; /* [2] */
    body.style.position = "relative"; /* [1] */
    body.style.overflow = "hidden"; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = "";
    html.style.overflow = "";
    body.style.position = "";
    body.style.overflow = "";
    body.style.paddingRight = "";

    scrollBlocked.current = false;
  };

  const changeBg = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
      // console.log(window.scrollY);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBg);

  return (
    <NavBar>
      <div className={navbar ? "wrapper active" : "wrapper "}>
        <div className="container">
          <div className="left">
            <Link to="/">
              <div className={burgerMenu ? "fixLogo logo" : "logo"}>
                <img src={Logo} alt="logo" />
              </div>
            </Link>
            <div className="menu">
              <Link
                to="/"
                onMouseEnter={() => {
                  setActivateBusiness(false);
                  setActivateProducts(false);
                }}
              >
                Home
              </Link>
              <div
                className="link"
                onMouseEnter={() => {
                  setActivateBusiness(false);
                  setActivateProducts(true);
                }}
              >
                <div
                  onClick={() => {
                    setActivateProducts(!activateProducts);

                    // setActivateProducts(false);
                  }}
                >
                  Products{" "}
                  <img
                    src={MenuArrow}
                    alt="arrow"
                    // className={activateProducts ? "activeArrow" : "inactiveArrow"}
                  />
                </div>

                <ul
                  className={activateProducts ? "productsMenu" : "hide"}
                  onMouseEnter={() => setActivateBusiness(false)}
                  onMouseLeave={() => setActivateProducts(false)}
                  // onMouseLeave={() => setActivateProducts(!activateProducts)}
                >
                  <div>
                    <Link
                      to="/clinker"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Clinker
                    </Link>
                    <Link
                      to="/limestone"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Limestone
                    </Link>
                    <Link
                      to="/gypsum"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Gypsum
                    </Link>
                    <Link
                      to="/pozzolana"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Pozzolana
                    </Link>
                    <Link
                      to="/slag"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Slag
                    </Link>
                    <Link
                      to="/construction_stone"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Construction stone
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/about"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      About
                    </Link>
                    <Link
                      to="/contact"
                      onClick={() => {
                        setActivateProducts(!activateProducts);
                        setActivateBusiness(false);
                      }}
                    >
                      Contact Us
                    </Link>
                    <div className="subscribe">
                      <h1>Subscribe Newsletter</h1>
                      <div className="mail">
                        <p>
                          By subscribing to our mailing list you <br /> will
                          alwyas be updated
                        </p>
                        <div className="input">
                          <input type="email" placeholder="Your Email " />
                          <button
                            onClick={() => {
                              setActivateProducts(!activateProducts);
                              setActivateBusiness(false);
                            }}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
              <div
                className="link"
                onClick={() => {
                  setActivateBusiness(!activateBusiness);

                  // setActivateProducts(false);
                }}
                onMouseEnter={() => {
                  setActivateBusiness(true);
                  setActivateProducts(false);
                }}
              >
                Business{" "}
                <img
                  src={MenuArrow}
                  alt="arrow"
                  // className={activateBusiness ? "activeArrow" : "inactiveArrow"}
                />
                <ul
                  className={activateBusiness ? "businessMenu" : "hide"}
                  onMouseLeave={() => setActivateBusiness(false)}
                  // onMouseLeave={() => setActivateBusiness(!activateBusiness)}
                >
                  <Link to="/trading">Trading</Link>
                  <Link to="/shipping">Shipping</Link>
                  {/* <Link to="/supply">Supply</Link> */}
                </ul>
              </div>
              <Link
                to="/about"
                onMouseEnter={() => {
                  setActivateBusiness(false);
                  setActivateProducts(false);
                }}
              >
                About
              </Link>
              <Link
                to="/news"
                onMouseEnter={() => {
                  setActivateBusiness(false);
                  setActivateProducts(false);
                }}
              >
                News
              </Link>
            </div>
          </div>
          <div className="action">
            <Link to="/contact">Contact</Link>
          </div>
          <div
            className={burgerMenu ? "burgerMenu fixBurger" : "burgerMenu"}
            onClick={() => {
              setBurgerMenu(!burgerMenu);
              burgerMenu ? allowScroll() : blockScroll();
            }}
          >
            <img src={Burger} alt="burger menu" />
          </div>
        </div>

        {/* mobile burger menu*/}
        {burgerMenu && (
          <MobileMenu>
            <div className="container">
              <Link
                to="/"
                onClick={() => {
                  setBurgerMenu(!burgerMenu);
                  allowScroll();
                }}
              >
                Home
              </Link>
              <div
                className="link"
                onClick={() => {
                  setActivateProducts(!activateProducts);
                  setActivateBusiness(false);
                }}
              >
                Products{" "}
                <img
                  src={MenuArrow}
                  alt="arrow"
                  // className={activateProducts ? "activeArrow" : "inactiveArrow"}
                />
                <ul
                  className={activateProducts ? "showProduct" : "hide"}
                  onMouseLeave={() => {
                    setActivateProducts(!activateProducts);
                  }}
                  onClick={() => {
                    setBurgerMenu(false);
                    allowScroll();
                  }}
                >
                  <Link to="/clinker">CLINKER</Link>
                  <Link to="/limestone">LIMESTONE</Link>
                  <Link to="/gypsum">GYPSUM</Link>
                  <Link to="/pozzolana">POZZOLANA</Link>
                  <Link to="/slag">SLAG</Link>
                  <Link to="/construction_stone">
                    CONSTRUCTION <br /> STONE
                  </Link>
                </ul>
              </div>
              <div
                className="link"
                onClick={() => {
                  setActivateBusiness(!activateBusiness);
                  setActivateProducts(false);
                }}
              >
                Business{" "}
                <img
                  src={MenuArrow}
                  alt="arrow"
                  // className={activateBusiness ? "activeArrow" : "inactiveArrow"}
                />
                <ul
                  className={activateBusiness ? "showBusiness" : "hide"}
                  onMouseLeave={() => setActivateBusiness(!activateBusiness)}
                  onClick={() => {
                    setBurgerMenu(false);
                    allowScroll();
                  }}
                >
                  <Link to="/trading">Trading</Link>
                  <Link to="/shipping">Shipping</Link>
                  {/* <Link to="/supply">Supply</Link> */}
                </ul>
              </div>
              <Link
                to="/about"
                onClick={() => {
                  setBurgerMenu(!burgerMenu);
                  allowScroll();
                }}
              >
                About
              </Link>
              <Link
                to="/news"
                onClick={() => {
                  setBurgerMenu(!burgerMenu);
                  allowScroll();
                }}
              >
                News
              </Link>
              <Link
                to="/contact"
                onClick={() => {
                  setBurgerMenu(!burgerMenu);
                  allowScroll();
                }}
              >
                Contact
              </Link>
            </div>
          </MobileMenu>
        )}
      </div>
    </NavBar>
  );
};

export default Nav;

const NavBar = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  font-family: "Josefin Sans", sans-serif;
  z-index: 100;

  .wrapper {
    padding: 1.5rem 0;
  }
  .wrapper.active {
    background-color: ${(props) => props.theme.colors.nav};
  }

  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    display: flex;
    column-gap: 5rem;
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 2.5rem;
  }
  .link {
    color: ${(props) => props.theme.colors.default};
    text-transform: uppercase;
    cursor: pointer;
  }

  .activeArrow {
    transform: rotate(180deg);
    transition: 0.3s ease;
  }

  .inactiveArrow {
    transform: rotate(0deg);
    transition: 0.3s ease;
  }

  .hide {
    display: none;
  }

  .menu a,
  .action a {
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
    font-size: ${(props) => props.theme.fontSizes.p};
    text-transform: uppercase;
    position: relative;
  }

  .action a {
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0.8rem 1.5rem;
    border-radius: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: #37487a;
      color: ${(props) => props.theme.colors.default};
    }
  }

  .menu > a {
    &::after {
      content: "";
      display: block;
      height: 3px;
      background-color: ${(props) => props.theme.colors.default};
      position: absolute;
      bottom: -5px;
      width: 0%;
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      width: 100%;
    }
  }

  .subscribe {
    margin-top: 1rem;
    border-top: 1px solid ${(props) => props.theme.colors.default};
    padding: 1rem 0;
  }

  .subscribe p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 400;
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
    text-transform: none;
  }

  .subscribe h1 {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 400;
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
  }

  .input {
    display: flex;
    gap: 5px;
    margin-top: 10px;
  }

  .input input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
  }

  .input button {
    width: fit-content;
    padding: 0.6rem;
    border-radius: 5px;
    border: none;
    background-color: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.small};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${(props) => props.theme.colors.default};
      color: ${(props) => props.theme.colors.black};
      transition: all 0.2s ease-in-out;
    }
  }

  .burgerMenu {
    display: none;
  }

  .productsMenu {
    position: absolute;
    top: 5.2rem;
    display: flex;
    min-width: 600px;
    /* column-gap: 2rem; */
    /* flex-direction: column; */
    /* backdrop-filter: blur(400px); */
    list-style: none;
    /* width: 32%; */
    background-color: ${(props) => props.theme.colors.nav};
    border-radius: 10px;
    overflow: hidden;
  }

  .productsMenu > div {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    row-gap: 10px;
  }

  .productsMenu div:first-child {
    background-color: ${(props) => props.theme.colors.caption};
  }

  .businessMenu {
    position: absolute;
    top: 5.2rem;
    display: flex;
    flex-direction: column;
    /* backdrop-filter: blur(400px); */
    list-style: none;
    width: 32%;
    background-color: ${(props) => props.theme.colors.nav};
    border-radius: 10px;
    padding: 2rem;
    row-gap: 10px;
  }

  .productsMenu a,
  .businessMenu a {
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    position: relative;
    text-decoration: underline;
    text-transform: none;
    /* border-bottom: 1px solid ${(props) => props.theme.colors.navBorder}; */
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 0 1rem;
    }
    .menu,
    .action {
      display: none;
    }
    .burgerMenu {
      display: block;
      z-index: 6000;
    }

    .logo {
      position: relative;
      z-index: 6000;
    }
  }
`;

const MobileMenu = styled.nav`
  overscroll-behavior: none;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 5000;
  min-height: 150vh;
  position: absolute;
  width: 100%;
  top: -5vh;
  color: ${(props) => props.theme.colors.default};

  .container {
    display: none;
    flex-direction: column;
    row-gap: 3rem;
    padding-top: 24vh;
    width: fit-content;
    align-items: flex-start;
  }

  /* padding-left: 20vw; */

  a,
  div {
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
    font-size: ${(props) => props.theme.fontSizes.p};
    text-transform: uppercase;
    width: fit-content;
  }

  .showProduct,
  .showBusiness {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    margin-top: 2rem;
    position: relative;
    /* background-color: ${(props) => props.theme.colors.mobileMenuBg}; */
    padding: 0;
    a {
      font-size: ${(props) => props.theme.fontSizes.small};
      text-transform: lowercase;
      color: ${(props) => props.theme.colors.mobileMenu};
      border-bottom: 1px solid var(--mobile-menu);
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      display: flex;
    }
  }
`;
