import React from "react";
import styled from "styled-components";

const ContactForm = () => {
  return (
    <FormStyle>
      <div className="container">
        <div className="title">
          <h1>Schedule a Meeting</h1>
        </div>
        <div className="form">
          <div className="left">
            <div className="email">
              <h1>Email</h1>
              <input type="email" />
            </div>
            <div className="phone">
              <h1>Phone</h1>
              <input type="text" />
            </div>
          </div>
          <div className="right">
            <div>
              <h1>Message</h1>
              <textarea rows={10} cols={45} />
            </div>

            <button>Submit</button>
          </div>
        </div>
      </div>
    </FormStyle>
  );
};

export default ContactForm;

const FormStyle = styled.div`
  background-color: #d9e2fe;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1600px;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4rem;
    padding: 5rem 0;
  }

  .title h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  .form {
    width: 100%;
    min-width: 800px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 10rem;
  }

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .form h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    padding-bottom: 0.4rem;
  }

  .right h1 {
    padding-bottom: 0.5rem;
  }

  input {
    /* min-width: 300px; */
    min-height: 4rem;
  }

  input,
  textarea {
    border: none;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    font-family: "Open Sans", sans-serif;
    width: 100%;
  }

  button {
    width: fit-content;
    color: ${(props) => props.theme.colors.default};
    font-family: "Open Sans", sans-serif;
    background: ${(props) => props.theme.colors.primary};
    border: none;
    padding: 1rem 3.5rem;
    border-radius: 2rem;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.p};
    cursor: pointer;
    align-self: center;
  }

  @media only screen and (max-width: 600px) {
    .container {
      width: 90%;
      row-gap: 2rem;
      min-width: unset;
    }
    .form {
      flex-direction: column;
      row-gap: 1rem;
      min-width: unset;
    }

    .left,
    .right {
      row-gap: 1rem;
    }

    .title h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .form h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    input {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      min-height: 3rem;
    }
    textarea {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
