import React, { useState } from "react";
import styled from "styled-components";
import innovation1 from "../assets/img/innovations/inovation1.jpg";
import innovation2 from "../assets/img/innovations/inovation2.jpg";
import innovation3 from "../assets/img/innovations/inovation3.jpg";
import innovation4 from "../assets/img/innovations/inovation4.jpg";
import innovation5 from "../assets/img/innovations/inovation5.jpg";
import innovation6 from "../assets/img/innovations/inovation6.png";
import innovation7 from "../assets/img/innovations/inovation7.jpg";
import innovation8 from "../assets/img/innovations/inovation8.jpg";
import innovation9 from "../assets/img/innovations/inovation9.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";

const Innovations = () => {
  const [photoModal1, setPhotoPodal1] = useState(false);
  const [photoModal2, setPhotoPodal2] = useState(false);
  const [photoModal3, setPhotoPodal3] = useState(false);
  const [photoModal4, setPhotoPodal4] = useState(false);
  const [photoModal5, setPhotoPodal5] = useState(false);
  const [photoModal6, setPhotoPodal6] = useState(false);
  const [photoModal7, setPhotoPodal7] = useState(false);
  const [photoModal8, setPhotoPodal8] = useState(false);
  const [photoModal9, setPhotoPodal9] = useState(false);
  return (
    <InnovationStyle>
      {(photoModal1 ||
        photoModal2 ||
        photoModal3 ||
        photoModal4 ||
        photoModal5 ||
        photoModal6 ||
        photoModal7 ||
        photoModal8 ||
        photoModal9) && (
        <div
          className="overlay3"
          onClick={() => {
            setPhotoPodal1(false);
            setPhotoPodal2(false);
            setPhotoPodal3(false);
            setPhotoPodal4(false);
            setPhotoPodal5(false);
            setPhotoPodal6(false);
            setPhotoPodal7(false);
            setPhotoPodal8(false);
            setPhotoPodal9(false);
          }}
        ></div>
      )}
      <div className="container">
        {photoModal1 && (
          <div className="modal">
            <img src={innovation1} alt="innovation" />
          </div>
        )}
        {photoModal2 && (
          <div className="modal">
            <img src={innovation2} alt="innovation" />
          </div>
        )}
        {photoModal3 && (
          <div className="modal">
            <img src={innovation3} alt="innovation" />
          </div>
        )}
        {photoModal4 && (
          <div className="modal">
            <img src={innovation4} alt="innovation" />
          </div>
        )}
        {photoModal5 && (
          <div className="modal">
            <img src={innovation5} alt="innovation" />
          </div>
        )}
        {photoModal6 && (
          <div className="modal">
            <img src={innovation6} alt="innovation" />
          </div>
        )}
        {photoModal7 && (
          <div className="modal">
            <img src={innovation7} alt="innovation" />
          </div>
        )}
        {photoModal8 && (
          <div className="modal">
            <img src={innovation8} alt="innovation" />
          </div>
        )}
        {photoModal9 && (
          <div className="modal">
            <img src={innovation9} alt="innovation" />
          </div>
        )}

        <h1>Innovations</h1>
        <div className="gallary">
          <div className="slider">
            <Swiper
              className="slides"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              loop={true}
              breakpoints={{
                0: {
                  width: 1600,
                  slidesPerView: 4.5,
                  spaceBetween: 20,
                },
                1400: {
                  width: 1400,
                  slidesPerView: 3.5,
                  spaceBetween: 300,
                },

                1900: {
                  width: 1900,
                  slidesPerView: 4.5,
                  spaceBetween: 300,
                },
              }}
            >
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(true);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation1} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(true);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation2} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(true);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation3} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(true);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation4} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(true);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation5} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(true);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation6} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(true);
                    setPhotoPodal8(false);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation7} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(true);
                    setPhotoPodal9(false);
                  }}
                >
                  <img src={innovation8} alt="innovation" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="card"
                  onClick={() => {
                    setPhotoPodal1(false);
                    setPhotoPodal2(false);
                    setPhotoPodal3(false);
                    setPhotoPodal4(false);
                    setPhotoPodal5(false);
                    setPhotoPodal6(false);
                    setPhotoPodal7(false);
                    setPhotoPodal8(false);
                    setPhotoPodal9(true);
                  }}
                >
                  <img src={innovation9} alt="innovation" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </InnovationStyle>
  );
};

export default Innovations;

const InnovationStyle = styled.div`
  /* min-height: 100vh; */
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
  }

  .modal {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    z-index: 100;
  }

  .overlay3 {
    background-color: black;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    opacity: 0.7;
  }

  .modal img {
    width: 800px;
  }
  h1 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
  }

  .card {
    min-width: 400px;
    overflow: hidden;
  }

  img {
    width: 100%;
    cursor: pointer;
  }

  .gallary {
    max-width: 1200px;
  }

  @media only screen and (max-width: 600px) {
    .card {
      min-width: 300px;
    }
    h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }
    .gallary {
      max-width: 400px;
    }
    .modal img {
      width: 100%;
    }
  }
`;
