import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import bg from "../assets/img/supply/bg.jpg";

const Supply = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <SupplyStyle>
        <div className="overlay"></div>
        <div className="banner">
          <h1>Supply</h1>
        </div>
        <div className="details">
          <p>
            Peakward provides a first class cost effective business solution
            that directly addresses our clients supply chain requirements. We
            assist and participate with our customers to develop and implement
            customized, cost-effective and sustainable logistic and supply chain
            solutions that will enhance their competitiveness in a dynamic
            market. As part of our strategic approach to support global
            industrialization, we have integrated our different dry bulk related
            businesses into an integrated supply chain to provide our customers
            with cost effective source-to destination supply chain solutions
            that are customized to meet the needs of the market. Our
            comprehensive suite of logistic services extents the entire supply
            chain from source to destination. We provide services that comprise
            trucking, barging, ocean shipping, transshipment, ports handling,
            stockpiling and local distribution through our offices, associate
            companies and strategic networks in different parts of the world.
            Teaming up with local partners Peakward also has developed
            capability of local delivery and supply of dry bulk commodity up to
            buyer's plant warehouse/storage yard basis in UAE, Bangladesh and
            Vietnam. Peakward also handle many different dry bulk commodities
            that include Cement, Clinker, Limestone, Gypsum, Pozzolana, Stone &
            Aggregates, Coal, Fly Ash, Iron Ore, Food Grains and Fertilizers.
            Depending on customers requirements, Peakward is able to customize
            supply chain solutions to meet their specific needs. Peakward also
            work with strategic partners to provide the entire supply chain
            solution. Peakward has reached the position of one of the top
            physical suppliers in the Asia region. At Peakward our objective is
            to create, maintain and be a part of an efficient Supply Chain that
            efforts our clients access to high quality products at minimum cost,
            within the shortest possible time-frame.
          </p>
        </div>
      </SupplyStyle>
    </>
  );
};

export default Supply;

const SupplyStyle = styled.div`
  z-index: 1;
  .banner {
    min-height: 100vh;
    /* background-color: rgba(13, 24, 86, 0.65); */
    background-image: url(${bg});
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.586);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100vh;
    width: 100%;
    z-index: 2;
  }

  .banner h1 {
    z-index: 3;
    font-size: 3rem;
    font-weight: 600;
    font-family: "Josefin Sans", sans-serif;
  }

  .details {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
  }

  .details p {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    line-height: 2rem;
    text-align: justify;
  }

  @media only screen and (max-width: 600px) {
    .details {
      width: 90%;
      padding: 5rem 0;
    }
  }
`;
