import React from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NewsBanner from "../components/NewsBanner";
import NewsList from "../components/NewsList";

const News = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <NewsBanner />
      <NewsList />
    </>
  );
};

export default News;
