import React, { useEffect } from "react";

import AboutBanner from "../components/AboutBanner";
import AboutDetails from "../components/AboutDetails";
import AboutGoal from "../components/AboutGoal";
import Values from "../components/Values";
import Innovations from "../components/Innovations";
import Products from "../components/Products";
import { useLocation } from "react-router-dom";
import AboutTimeline from "../components/AboutTimeline";

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <AboutBanner />
      <AboutDetails />
      <Innovations />
      <Products bg={"#D9E2FE"} />
      <AboutGoal />
      <Values />
      <AboutTimeline />
    </>
  );
};

export default About;
