import Service1 from "../assets/img/service1.svg";
import Service2 from "../assets/img/service2.svg";
import Service3 from "../assets/img/service3.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <ServicesStyle>
      <div className="container">
        <div className="titles">
          <h1 className="title1">OUR SERVICES</h1>
          <h1 className="title2">What we do</h1>
          <p className="title3">
            Our business spans globally across 8 countries.
          </p>
        </div>
        <div className="service">
          <div className="service-1">
            <img src={Service1} alt="service icon" />
            <h1>Trading</h1>
            <p>
              Peakward's Trading arm focuses mainly on commodities such as
              Cement, Clinker, Gypsum, Limestone, BF Slag, Pozzolana, Stone
              Aggregates, Fly Ash, Coal, Fertilizer and Iron Ore that are
              essential in supporting global rapid growth.
            </p>
          </div>
          <div className="service-2">
            <img src={Service2} alt="service icon" />
            <h1>Shipping</h1>
            <p>
              Peakward has exclusive shipping team which stays updated with
              great zeal of response on freight and price index worldwide to our
              valuable buyers to cater supply chain requirements on the right
              time at right price.
            </p>
          </div>
          <div className="service-3">
            <img src={Service3} alt="service icon" />
            <h1>Logistics</h1>
            <p>
              We provide last-mile services to our clients. We can arrange on
              project delivery if that is required by the buyers. We have
              supported the construction of various mega projects through our
              supply chain.
            </p>
          </div>
        </div>
        <Link to="/about">
          <button>Learn More</button>
        </Link>
      </div>
    </ServicesStyle>
  );
};

export default Services;

const ServicesStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1600px;
    margin: auto;
    /* min-height: 100vh; */
    row-gap: 5rem;
    padding: 5rem 0;
  }

  .titles {
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .titles .title1 {
    color: ${(props) => props.theme.colors.highlight};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 600;
  }

  .titles .title2 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  .titles .title3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }

  .service {
    display: flex;
    column-gap: 3rem;
  }

  .service div {
    /* border-left: 3px solid #949dce; */
    border: 2px solid ${(props) => props.theme.colors.serviceBorder};
    border-radius: 10px;
    padding: 1rem 2rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 1rem;
    text-align: center;
    max-width: 370px;
    cursor: default;
    //default cursor type

    &:hover {
      /* background-color: ${(props) => props.theme.colors.serviceBg}; */
    }
  }

  .service div img {
    width: 100px;
    min-height: 100px;
    margin: auto;
  }

  .service div h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-family: "Josefin Sans", sans-serif;
  }

  .service div p {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
  }

  button {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    background: transparent;
    width: fit-content;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.p};
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background-color: ${(props) => props.theme.colors.btnHover};
      color: ${(props) => props.theme.colors.default};
      transition: 0.3s ease;
    }
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
      /* padding-left: 2rem;
      padding-right: 2rem; */
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      row-gap: 2rem;
    }

    .service {
      display: flex;
      flex-direction: column;
      row-gap: 2.5rem;
    }

    .titles .title1 {
      font-size: ${(props) => props.theme.fontSizes.h6};
      line-height: ${(props) => props.theme.lineHeights.h6};
      font-weight: 600;
    }

    .titles .title2 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .titles .title3 {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }

    .service div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 1rem;
      text-align: center;
    }

    .service div p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      font-weight: 400;
    }
  }
`;
