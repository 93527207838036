import React from "react";
import styled from "styled-components";
import Contact1 from "../assets/img/contact/contact1.png";
import Contact2 from "../assets/img/contact/contact2.png";
// import Contact3 from "../assets/img/contact/contact3.png";
import Contact4 from "../assets/img/contact/contact4.png";
import Contact5 from "../assets/img/contact/contact5.png";
import Contact6 from "../assets/img/contact/contact6.png";
import Contact7 from "../assets/img/contact/contact7.png";
import Contact8 from "../assets/img/contact/contact8.png";
import Contact9 from "../assets/img/contact/contact9.png";

const ContactList = () => {
  return (
    <ContactStyle>
      <div className="container">
        <div className="title">
          <h1>Our Valuable Contacts</h1>
        </div>
        <div className="contacts">
          <div className="contact">
            <img src={Contact1} alt="country flag" />
            <p>Group Head Office</p>
            <p>Peakward Enterprises (Holdings) LTD.</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>2901 Pacific Plaza, 29th Floor</p>
              <p>410 Das Voeux Road West, Hong Kong.</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: (852) 2857 6126</p>
              <p>Fax: (852) 2857 6122</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>

          <div className="contact">
            <img src={Contact2} alt="country flag" />
            <p>BANGLADESH</p>
            <p>PEAKWARD BANGLASESH LTD.</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>Ahmed Tower (7th floor),</p>
              <p>28-30, Kemal Ataturk Avenue, Banani, </p>
              <p>Dhaka-1213, Banglaesh.</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: (880-2) 7112263, 7122141, 7112295</p>
              <p>Fax: (880-2) 9565109</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
          {/* <div className="contact">
            <img src={Contact3} alt="country flag" />
            <p>BRITISH VIRGIN ISLANDS</p>
            <p>SEAKING SHIPPING LTD.</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>
                P.O. Box 957 offshore incorporations <br /> center.
              </p>
              <p>Road Town, Tortola.</p>
              <p>British VirginIslands.</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Website: www.seakingshipping.net</p>
              <p>E-mail: operation@seakingshipping.net</p>
            </div>
          </div> */}
          <div className="contact">
            <img src={Contact4} alt="country flag" />
            <p>CHINA</p>
            <p>
              PEAKWARDS ENTERPRISE (BEIJING) <br /> COMPANY LTD.
            </p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>
                Suit 1701-1703 17/F, Huantengbeitang, <br />
                Business Building.
              </p>
              <p>No 37, South Grinding Mill Road.</p>
              <p>Chaoyang District, Beijing, PRC.</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: (0) 86-10-51909139 Ext: 17806</p>
              <p>Fax: (0) 86-10-51909120</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
          <div className="contact">
            <img src={Contact5} alt="country flag" />
            <p>TURKEY</p>
            <p>
              PEAKWARDS DENIZICLIK VE TICARET <br /> LIMITED STI.
            </p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>Konaklar Mah, Selvil Sok.</p>
              <p>13 Blok A Kapisi No: 12/B Kat: 2 Daire: 3</p>
              <p>4.Levent, 34330 Istanbul/Turkey.</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: +90 212 282 42 30 (31-32 PBX)</p>
              <p>Fax: +90 212 282 42 33</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
          <div className="contact">
            <img src={Contact6} alt="country flag" />
            <p>INDIA</p>
            <p>
              PEAKWARD ENTERPRISES INDIA <br /> PVT LTD
            </p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>2044/6 Chitragupta Road, Chunamandi</p>
              <p>Paharganj, New Delhi - 110055</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Mobile : +91 9873000756</p>
              <p>Email: info@peakward.com</p>
            </div>
          </div>
          <div className="contact">
            <img src={Contact7} alt="country flag" />
            <p>VIETNAM</p>
            <p>PEAKWARDS VIETNAM COMPANY LTD.</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>Suit 1104, VIGLACERA Tower.</p>
              <p>No. 01 Thang Long Road.</p>
              <p>Nam Tu Liem District Hanoi, Vietnam</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: (84-4) 38580235/36</p>
              <p>Fax: (84-4) 37955285</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
          <div className="contact">
            <img src={Contact8} alt="country flag" />
            <p>INDONESIA</p>
            <p>PT. PEAKWARD INDONESIA</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>Menara Global 20th Floor.</p>
              <p>JI, Gatot Subroto Kav, 27, Setiabudi.</p>
              <p>Kuninan Timur, Jakarta, Indonesia</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>
                Mobile: +628117228895, <br /> +628126727075
              </p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
          <div className="contact">
            <img src={Contact9} alt="country flag" />
            <p>UAE</p>
            <p>PEAKWARD ENTERPRISE FZE</p>
            <div className="address">
              <h1>ADDRESS</h1>
              <p>P.O. Box 10055, Ras Al Khaimah.</p>
              <p>United Arab Emirates</p>
            </div>
            <div className="contact-detail">
              <h1>CONTACT</h1>
              <p>Tel: 971-7-2077775</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p>
            </div>
          </div>
        </div>
      </div>
    </ContactStyle>
  );
};

export default ContactList;

const ContactStyle = styled.div`
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5rem;
    padding: 5rem 0;
  }

  .title h1 {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    font-family: "Josefin Sans", sans-serif;
  }

  .contacts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5rem;
  }

  .contact {
    background: ${(props) => props.theme.colors.contactBg};
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 0.8rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* row-gap: 1rem; */
    padding: 1rem;
    color: ${(props) => props.theme.colors.primary};
    min-width: 320px;
  }

  .contact img {
    width: 180px;
    height: 110px;
    border-radius: 0.8rem;
    margin-bottom: 1rem;
  }

  .contact .address,
  .contact .contact-detail {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    /* row-gap: 0.5rem; */
    padding-top: 1rem;
  }

  .contact .address h1,
  .contact .contact-detail h1 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-family: "Josefin Sans", sans-serif;
  }

  .contact p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
  }
`;
