import React from "react";
import styled from "styled-components";
import BannerImage from "../assets/img/contact/banner.jpg";

const ContactBanner = () => {
  return (
    <ContactBannerStyle>
      <div className="overlay"></div>
      <div className="text">
        <h1>Contact</h1>
        <p>Find our contact of 9 offices</p>
      </div>
    </ContactBannerStyle>
  );
};

export default ContactBanner;

const ContactBannerStyle = styled.div`
  min-height: 70vh;
  background-image: url(${BannerImage});
  background-size: cover;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.bannerBg};
    z-index: 2;
    height: 100%;
  }

  .text {
    z-index: 3;
    color: ${(props) => props.theme.colors.default};
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .text h1 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    line-height: ${(props) => props.theme.lineHeights.h2};
    font-weight: 800;
  }

  .text p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 400;
  }

  @media only screen and (max-width: 600px) {
    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
      line-height: ${(props) => props.theme.lineHeights.h4};
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }
  }
`;
