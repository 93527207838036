import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import pdf from "../assets/documents/gypsum.pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import clinkerInfo from "../assets/img/clinker/details.svg";
import limestoneInfo from "../assets/img/limestone/details.svg";
import gypsumInfo from "../assets/img/gypsum/details.svg";
import slagInfo from "../assets/img/slag/details.svg";
import stoneInfo from "../assets/img/stone/details.svg";

const ProductDetails = ({ title }) => {
  const handleDownload = () => {
    // Generate or fetch the PDF file URL
    // const pdfUrl = pdf;

    // // Create a virtual link element
    // const link = document.createElement("a");
    // link.href = pdfUrl;
    // link.download = "peakward - Gypsum Brochure.pdf";

    // // Dispatch a click event on the link element
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(`${pdf}`, "_blank");
  };

  return (
    <ProductDetailsStyle>
      <div className="container">
        <div className="text">
          {title === "Clinker" && (
            <>
              <p>Clinker - Highlights</p>
              <h1>
                Supplying top quality Clinker with consistent quality record
              </h1>
              <ul>
                <li>
                  Yearly supplying close to million tons of Clinker to
                  Bangladesh market originated from China, Vietnam, Indonesia,
                  Taiwan, Thailand, India and Persian Gulf.
                </li>
                <li>
                  Peakward has supplied close to three million tons of clinker
                  to National Cement Co. of Dubai, Sharjah Cement Co of Sharjah,
                  UAE, Qatar National Cement Co of Qatar and Farz & Khuzestan
                  Cement Co., the biggest cement group of Gulf region.
                </li>
                <li>
                  In the year 2000 Peakward supplied 500,000 MTs of Clinker to
                  HC Trading.
                </li>
                <li>
                  Peakward has extensive relationship with large Cement group of
                  China, Vietnam, Indonesia, Thailand, India, Taiwan, Malaysia,
                  Turkey, Egypt and Persian-Gulf.
                </li>
              </ul>
            </>
          )}
          {title === "Limestone" && (
            <>
              <p>limestone - Highlights</p>
              <h1>
                Supplying top quality Limestone with consistent quality record
              </h1>
              <ul>
                <li>
                  Peakward supplying huge quantity of Limestone from Vietnam and
                  Oman to Bangladesh on regular basis.
                </li>
                <li>
                  Peakward can supply Construction Grade Size: (5-10mm, 10-20mm)
                  Limestone aggregate as per buyer's requirements.
                </li>
                <li>
                  Packing can be arranged based on: Bulk in vessels Lump
                  Limestone, In Bulk (Big Bags) of 1-2MT Sling Bags, In 50kg
                  bags in 1-2MT Sling Bags or Buyers option.
                </li>
              </ul>
            </>
          )}
          {title === "Gypsum" && (
            <>
              <div className="gypsum-titles">
                <p>Highest Quality Natural Gypsum</p>
                <h1>
                  Experience the unmatched purity and finest quality with our
                  premium gypsum.
                </h1>

                <button onClick={handleDownload}>
                  Download Brochure <FontAwesomeIcon icon={faFileArrowDown} />
                </button>
              </div>
            </>
          )}
          {title === "Slag" && (
            <>
              <p>Slag - Highlights</p>
              <h1>Supplying top quality Slag with consistent quality record</h1>
              <ul>
                <li>
                  Peakward is a leading supplier, delivering a huge quantity of
                  high-quality Slag from various sources to Bangladesh on a
                  regular basis.
                </li>
                <li>
                  Peakward can supply Construction Grade Slag in different
                  sizes, ranging from fine particles to larger aggregates,
                  tailored to meet the specific requirements of buyers.
                </li>
                <li>
                  The packaging options for Slag provided by Peakward include
                  bulk shipments in vessels, lump Slag in bulk bags weighing
                  1-2MT with the convenience of sling bags, and also the option
                  to pack Slag in 50kg bags within 1-2MT sling bags.
                  Alternatively, customized packaging options can be arranged as
                  per the buyer's preference.
                </li>
                <li>
                  Slag, known for its versatility and various applications, is
                  widely used in construction, road building, and other
                  infrastructure projects. Peakward ensures that the supplied
                  Slag maintains consistent quality, adhering to stringent
                  quality control measures throughout the supply chain.
                </li>
                <li>
                  Over 20 years ago, Peakward started to export blast-furnace
                  slag in international construction market.
                </li>
                <li>
                  Our slag is used mainly for producing Slag cement and
                  ready-mix concrete.
                </li>
                <li>
                  The BF Slag supplied by us have almost 90%-96% Glass content
                  and soft to grind.
                </li>
                <li>
                  GBFS is supplied in bulk while GGBFS is supplied both in big
                  bags and in bulk.
                </li>
              </ul>
            </>
          )}
          {title === "Construction Stone" && (
            <>
              <p>Construction aggregates</p>
              <h1>Sizes of gabbro comes in the following sizes:</h1>

              <ul id="stone-list">
                <div>
                  <li>0-5mm (sand)</li>
                  <li>5-10mm</li>
                  <li>10-20mm</li>
                </div>
                <div>
                  <li>20-30mm</li>
                  <li>20-40mm</li>
                  <li>40-60mm</li>
                </div>
              </ul>
            </>
          )}
          {title === "Pozzolana" && (
            <div className="pozzolana-titles">
              <p>ENVIRONMENTAL BENEFITS OF POZZOLANA</p>
              <h1>Building a better world with Natural Pozzolana</h1>
              <Link
                to="https://drive.google.com/drive/folders/1-Qd6Z7xieCHTXFC09wqgat_AH01HYs-7?usp=drive_link"
                target="_blank"
              >
                <button>Download Documents</button>
              </Link>
            </div>
          )}
        </div>

        {title === "Clinker" && (
          <div className="image">
            <img src={clinkerInfo} alt="data" />
          </div>
        )}

        {title === "Limestone" && (
          <div className="image">
            <img src={limestoneInfo} alt="data" />
          </div>
        )}

        {title === "Gypsum" && (
          <div className="image">
            <img src={gypsumInfo} alt="data" />
          </div>
        )}

        {title === "Slag" && (
          <div className="image">
            <img src={slagInfo} alt="data" />
          </div>
        )}

        {title === "Construction Stone" && (
          <div className="image">
            <img src={stoneInfo} alt="data" />
          </div>
        )}
        {title === "Pozzolana" && (
          <div className="pozzolana-highlight">
            <ul>
              <li>Natural Pozzolana is the best choice of 3rd Materials</li>
              <li>ZERO man-made CO2 emission</li>
              <li>
                Natural Pozzolana reduces carbon footprint of Construction
                Projects
              </li>
              <li>
                Less power consumption and Prolonged Grinding Equipment life
                because of how soft Pozzolana is
              </li>
              <li>Pozzolana is cheaper than othe 3rd materials</li>
              <li>Free from poisonous heavy elements (Cd, Hg, Pb)</li>
              <li>Reduce Creep and Shrinkage of hardened Concrete</li>
            </ul>
          </div>
        )}
      </div>
    </ProductDetailsStyle>
  );
};

export default ProductDetails;

const ProductDetailsStyle = styled.div`
  /* min-height: 100vh; */
  background-color: ${(props) => props.theme.colors.secondary};

  .container {
    display: flex;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 1600px;
    padding: 5rem 0;
  }
  .text {
    color: ${(props) => props.theme.colors.primary};
    width: 800px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .text p {
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    text-transform: uppercase;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    color: ${(props) => props.theme.colors.highlight};
  }

  .text h1 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
  }

  .text ul {
    width: 650px;
    display: flex;
    flex-direction: column;
    margin-left: 1.2rem;
    /* row-gap: 1rem; */
  }

  .text ul li {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 400;
  }

  .pozzolana-titles,
  .gypsum-titles {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .pozzolana-titles p {
    color: #166426;
  }

  .gypsum-titles h1 {
    max-width: 600px;
  }
  .pozzolana-titles button,
  .gypsum-titles button {
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.p};
    background-color: #166426;
    border-radius: 20.5px;
    padding: 0.8rem 1.2rem;
    font-weight: 700;
    border: none;
    cursor: pointer;
    width: fit-content;
  }
  .gypsum-titles button {
    background-color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .pozzolana-highlight {
    color: #58ba6e;
    border-radius: 48px;
    background-color: rgba(88, 245, 113, 0.14);
    padding: 2rem;
    max-width: 600px;
  }

  .pozzolana-highlight ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .pozzolana-highlight ul li {
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
    cursor: default;
    transition: all 0.3s;

    &:hover {
      transform: translateX(20px);
      color: #166426;
    }
  }

  #stone-list {
    display: flex;
    flex-direction: row;
    gap: 4rem;
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
    }

    .text {
      padding: 5rem 2rem;
    }
  }
  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column;
      padding: 5rem 1rem;
      row-gap: 2rem;
    }

    .text {
      width: auto;
      padding: unset;
      text-align: center;
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .text ul {
      width: auto;
      padding-left: 1rem;
      text-align: left;
    }

    .text ul li {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image img {
      width: 90%;
    }

    .pozzolana-titles button,
    .gypsum-titles button {
      align-self: center;
    }

    .pozzolana-highlight ul li {
      font-size: ${(props) => props.theme.fontSizes.h6};
      line-height: ${(props) => props.theme.lineHeights.h6};
    }
  }
`;
