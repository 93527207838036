import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Trading from "./pages/Trading";
import Shipping from "./pages/Shipping";
import Supply from "./pages/Supply";
import Product from "./pages/Product";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import News from "./pages/News";
import { theme } from "./theme";
import NewsDetail from "./pages/NewsDetail";
// import { LocomotiveScrollProvider } from "react-locomotive-scroll";
// import { createPortal } from "react-dom";
// import { useState } from "react";

const App = () => {
  // const ref = useRef(null);

  // const options = {
  //   smooth: true,
  // };

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        {/* <LocomotiveScrollProvider options={options} containerRef={ref}>
        <main data-scroll-container ref={ref} id="nav"> */}
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/clinker" element={<Product />} />
          <Route path="/limestone" element={<Product />} />
          <Route path="/gypsum" element={<Product />} />
          <Route path="/pozzolana" element={<Product />} />
          <Route path="/slag" element={<Product />} />
          <Route path="/construction_stone" element={<Product />} />
          <Route path="/trading" element={<Trading />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/supply" element={<Supply />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="*" element={<div>unknown url</div>} />
        </Routes>
        <Footer />
        {/* </main>
      </LocomotiveScrollProvider> */}
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
