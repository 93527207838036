import React, { useEffect, useState } from "react";
import AboutIntro from "../components/AboutIntro";
import HomeBanner from "../components/HomeBanner";
import Partners from "../components/Partners";
import Products from "../components/Products";
import Services from "../components/Services";
import Team from "../components/Team";
import LatestNews from "../components/LatestNews";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import closeIcon from "../assets/img/modal-close.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Home = () => {
  const [sellerModal, setSellerModal] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Container>
        <HomeBanner setSellerModal={setSellerModal} />
        <AboutIntro />
        {sellerModal && <div className="overlay1"></div>}
        {sellerModal && (
          <Modal className="modal">
            <div className="container">
              <div className="close">
                <button onClick={() => setSellerModal(false)}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>

              <div className="title">
                <h1>Schedule a Meeting</h1>
              </div>
              <div className="form">
                <div className="left">
                  <div className="email">
                    <h1>Email</h1>
                    <input type="email" />
                  </div>
                  <div className="phone">
                    <h1>Phone</h1>
                    <input type="text" />
                  </div>
                </div>
                <div className="right">
                  <div>
                    <h1>Message</h1>
                    <textarea rows={7} cols={45} />
                  </div>

                  <button>Submit</button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </Container>
      <Services />
      <Products />
      <Team />
      <Partners />
      <LatestNews />
    </div>
  );
};

export default Home;

const Container = styled.div`
  position: relative;
  .overlay1 {
    background-color: black;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    opacity: 0.5;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 100;
  background-color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  width: 50%;
  right: 0;
  left: 0;
  height: fit-content;
  padding: 3rem 4rem;

  .close {
    /* width: 90%; */
    /* border: 1px solid black; */
    text-align: right;
    /* padding: 1rem; */
    position: absolute;
    top: 0;
    right: 0;
  }

  .container {
    max-width: 1600px;
    /* min-width: 600px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 4rem;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .title h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 10rem;
    /* min-width: 800px; */
  }

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .form h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    padding-bottom: 0.4rem;
  }

  .right h1 {
    padding-bottom: 0.5rem;
  }

  input,
  textarea {
    border: none;
    padding: 1rem;
    font-size: ${(props) => props.theme.fontSizes.p};
    border-radius: 0.5rem;
    font-family: "Open Sans", sans-serif;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  button {
    width: fit-content;
    color: ${(props) => props.theme.colors.default};
    font-family: "Open Sans", sans-serif;
    background: ${(props) => props.theme.colors.primary};
    border: none;
    padding: 1rem 3.5rem;
    border-radius: 2rem;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.p};
    cursor: pointer;
    align-self: center;
  }

  .close button {
    border-radius: 0;
    width: fit-content;
    padding: 0;

    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
  }

  .close button svg {
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 0;
    height: unset;
    max-width: unset;
    min-width: unset;
    .container {
      width: 90%;
      row-gap: 2rem;
      min-width: unset;
    }
    .form {
      flex-direction: column;
      row-gap: 1rem;
      min-width: unset;
    }

    .left,
    .right {
      row-gap: 1rem;
    }

    .title h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .form h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    input {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      padding: 10px;
    }
    textarea {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      padding: 10px;
    }

    .close {
      top: 4%;
    }
  }
`;
