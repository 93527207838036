import React from "react";
import styled from "styled-components";
import bg from "../assets/img/about/about-bg.jpg";
import aboutVideo from "../assets/video/about.mp4";

const AboutBanner = () => {
  return (
    <Banner>
      {/* <div className="overlay"></div> */}
      {/* <div className="text">
        <h1>About Peakward</h1>
        <p>
          Global presence in all our current and potential markets is an
          essential element of our business strategy. We are present in all
          regions where our customers are active, so that we can respond as
          effectively as possible to their needs. With support of our global
          network our experienced trade team will ensure that your business will
          be handled efficiently and without any delay.
        </p>
      </div> */}
      <video
        src={aboutVideo}
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          // height: "100%",
          objectFit: "cover",
          // position: "absolute",
          // right: 0,
          // left: 0,
        }}
      />
    </Banner>
  );
};
export default AboutBanner;

const Banner = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  /* min-height: 100vh; */
  /* background-image: url(${bg}); */
  /* background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  z-index: 1;
  position: relative; */
  /* .overlay {
    background: rgba(16, 27, 90, 0.783);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100%;
    width: 100%;
    z-index: 2;
  } */
  /* .text {
    z-index: 3;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    font-family: "Josefin Sans", sans-serif;
  } */

  /* .text h1 {
    font-size: 3rem;
    font-weight: 600;
  } */

  /* .text p {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.2rem;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
  } */
  video::-webkit-media-controls {
    display: none !important;
  }

  @media only screen and (max-width: 1400px) {
    /* .text {
      width: 70%;
      text-align: justify;
    }
    .text h1 {
      font-size: 3rem;
    } */
  }

  @media only screen and (max-width: 600px) {
    /* justify-content: center;
    align-items: center;
    padding: 10rem 1rem; */

    /* .text {
      width: 100%;
      text-align: center;
    }

    .text h1 {
      font-size: 2rem;
    }

    .text p {
      font-size: 1.2rem;
      line-height: 2rem;
    } */
  }
`;
