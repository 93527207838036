import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ProductTable = () => {
  const location = useLocation().pathname;
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (location === "/clinker") {
      setTitle("Clinker");
    } else if (location === "/limestone") {
      setTitle("Limestone");

      // console.log(quarry2);
    } else if (location === "/gypsum") {
      setTitle("Gypsum");
    } else if (location === "/pozzolana") {
      setTitle("Pozzolana");
    } else if (location === "/slag") {
      setTitle("Slag");
    } else if (location === "/construction_stone") {
      setTitle("Construction Stone");
    }
  }, [location]);

  return (
    <Table>
      <div className="productTableContainer">
        <h1>Typical Specifications</h1>
        {title == "Clinker" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Silione Dioxide</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>20.00 ~ 22.00</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Aluminium Oxide</td>
                <td>
                  Al<sub>2</sub>O<sub>3</sub>
                </td>
                <td>4.50 ~ 6.00</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Ferric Oxide</td>
                <td>
                  Fe<sub>2</sub>O<sub>3</sub>
                </td>
                <td>3.00 ~ 4.00</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Calcium Oxide</td>
                <td>CaO</td>
                <td>Min. 65</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Magnacium Oxide</td>
                <td>MgO</td>
                <td>≤ 2.0</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Sulfur Trioxide</td>
                <td>
                  SO<sub>3</sub>
                </td>
                <td>≤ 1.0</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Loss on Ignition</td>
                <td>LOI</td>
                <td>≤ 0.5</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Insoluble Residue</td>
                <td>IR</td>
                <td>≤ 0.5</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Moisture</td>
                <td>%</td>
                <td>Nill</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Free Lime</td>
                <td>F/CaO</td>
                <td>≤ 1.5</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Tricalcium Silicate</td>
                <td>
                  C<sub>3</sub>S
                </td>
                <td>≥ 58</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Dicalcium Silicate</td>
                <td>
                  C<sub>2</sub>S
                </td>
                <td>≤ 20</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Tricalcium Aluminate</td>
                <td>
                  C<sub>3</sub>A
                </td>
                <td>≤ 9</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Tetracalcium Ferrite</td>
                <td>
                  C<sub>4</sub>AF
                </td>
                <td>0000</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Lime Saturation Factor</td>
                <td>LSF</td>
                <td>92.00 ~ 97.00</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Chloride</td>
                <td>Cl-</td>
                <td>≤ 0.01</td>
              </tr>
              <tr>
                <td>17</td>
                <td>Total Alkalis</td>
                <td>
                  Na<sub>2</sub>O + 0.658K<sub>2</sub>O
                </td>
                <td>≤ 0.60</td>
              </tr>
              <tr>
                <td>18</td>
                <td>Color</td>
                <td>-</td>
                <td>Dark Grey</td>
              </tr>
              <tr>
                <td>19</td>
                <td>Size</td>
                <td>%</td>
                <td>-</td>
              </tr>
              <tr>
                <td>20</td>
                <td>Above 25mm</td>
                <td>-</td>
                <td>Max 5</td>
              </tr>
              <tr>
                <td>21</td>
                <td>Above 1mm & Below 25mm</td>
                <td>-</td>
                <td>Max 65</td>
              </tr>
              <tr>
                <td>22</td>
                <td>Below 1mm</td>
                <td>-</td>
                <td>Max 30</td>
              </tr>
            </tbody>
          </table>
        )}

        {title === "Pozzolana" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Silione Dioxide</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>62.008</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Aluminium Oxide</td>
                <td>
                  Al<sub>2</sub>O<sub>3</sub>
                </td>
                <td>17.810</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Ferric Oxide</td>
                <td>
                  Fe<sub>2</sub>O<sub>3</sub>
                </td>
                <td>6.252</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Calcium Oxide</td>
                <td>CaO</td>
                <td>2.882</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Magnacium Oxide</td>
                <td>MgO</td>
                <td>1.055</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Sulfur Trioxide</td>
                <td>
                  SO<sub>3</sub>
                </td>
                <td>0.006</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Titanium Oxide</td>
                <td>
                  TiO<sub>2</sub>
                </td>
                <td>0.717</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Manganese Oxide</td>
                <td>
                  MnO<sub>2</sub>
                </td>
                <td>0.063</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Sodium Oxide</td>
                <td>
                  Na<sub>2</sub>O
                </td>
                <td>1.420</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Ptassium Oxide</td>
                <td>
                  K<sub>2</sub>O
                </td>
                <td>1.203</td>
              </tr>
            </tbody>
          </table>
        )}
        {title === "Gypsum" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Silica</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>Max 2.0</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Total Sulfate</td>
                <td>
                  SO<sub>3</sub>
                </td>
                <td>Min 43.0</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Moisture</td>
                <td>%</td>
                <td>Max 2.0</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Purity (combined water, ASTM C 471-94)</td>
                <td>
                  CaSO<sub>4</sub>.<sub>2</sub>H<sub>2</sub>O
                </td>
                <td>Min 91.0</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Sieve Size</td>
                <td>%</td>
                <td>Max 5.0%</td>
              </tr>
            </tbody>
          </table>
        )}
        {title === "Slag" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Basicity</td>
                <td>
                  CaO/SiO<sub>2</sub>
                </td>
                <td>1.1-1.3</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Basicity</td>
                <td>
                  (CaO + MgO)/SiO<sub>2</sub>
                </td>
                <td>Min: 1.30</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Silica</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>Max: 35.0</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Calcium Oxide</td>
                <td>CaO</td>
                <td>Min: 40.0</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Iron Oxide</td>
                <td>
                  Fe<sub>2</sub>O<sub>3</sub>
                </td>
                <td>Max: 2.0</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Alumina</td>
                <td>
                  Al<sub>2</sub>O<sub>3</sub>
                </td>
                <td>Min: 10.0</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Magnesia</td>
                <td>MgO</td>
                <td>Max:10.0</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Sulfur</td>
                <td>
                  SO<sub>3</sub>
                </td>
                <td>Max: 2.0</td>
              </tr>
              <tr>
                <td>9</td>
                <td>Chloride</td>
                <td>%</td>
                <td>Max: 0.06</td>
              </tr>
              <tr>
                <td>10</td>
                <td>Moisture content</td>
                <td>%</td>
                <td>Max: 5.0</td>
              </tr>
              <tr>
                <td>11</td>
                <td>Insoluble Residue</td>
                <td>IR</td>
                <td>Max: 1.0</td>
              </tr>
              <tr>
                <td>12</td>
                <td>Titenium Oxide</td>
                <td>
                  TiO<sub>2</sub>
                </td>
                <td>Max: 0.5</td>
              </tr>
              <tr>
                <td>13</td>
                <td>Bulk density (loose, dry)</td>
                <td>Kg/L</td>
                <td>Max: 1.2</td>
              </tr>
              <tr>
                <td>14</td>
                <td>Glass content</td>
                <td>%</td>
                <td>Min: 95.0</td>
              </tr>
              <tr>
                <td>15</td>
                <td>Maximum grain size</td>
                <td>(mm)</td>
                <td>5mm: 100% Passing</td>
              </tr>
              <tr>
                <td>16</td>
                <td>Color</td>
                <td>-</td>
                <td>White</td>
              </tr>
            </tbody>
          </table>
        )}
        {title === "Limestone" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Calcium Oxide</td>
                <td>CaO</td>
                <td>52.0</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Silica Content</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>Max: 2.00</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Alumina+Iron</td>
                <td>
                  Al<sub>2</sub>O<sub>3</sub> + Fe<sub>2</sub>O<sub>3</sub>
                </td>
                <td>Max: 1.0</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Magnesium Oxide</td>
                <td>MgO</td>
                <td>Max: 1.0</td>
              </tr>
              <tr>
                <td>5</td>
                <td>LOI</td>
                <td>-</td>
                <td>Min: 42.0</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Purity</td>
                <td>%</td>
                <td>Min: 96.0</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Moisture</td>
                <td>%</td>
                <td>Min:1.0</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Bulk Density</td>
                <td>
                  t/m<sup>3</sup>
                </td>
                <td>Min: 1.30</td>
              </tr>
            </tbody>
          </table>
        )}
        {title === "Construction Stone" && (
          <table className="productSpec">
            <thead>
              <tr>
                <th>{""}</th>
                <th>Parameter</th>
                <th>Chemical Parameter</th>
                <th>Typical Range</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Calcium Oxide</td>
                <td>CaO</td>
                <td>52.0</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Silica Content</td>
                <td>
                  SiO<sub>2</sub>
                </td>
                <td>Max: 2.00</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Alumina+Iron</td>
                <td>
                  Al<sub>2</sub>O<sub>3</sub> + Fe<sub>2</sub>O<sub>3</sub>
                </td>
                <td>Max: 1.0</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Magnesium Oxide</td>
                <td>MgO</td>
                <td>Max: 1.0</td>
              </tr>
              <tr>
                <td>5</td>
                <td>LOI</td>
                <td>-</td>
                <td>Min: 42.0</td>
              </tr>
              <tr>
                <td>6</td>
                <td>Purity</td>
                <td>%</td>
                <td>Min: 96.0</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Moisture</td>
                <td>%</td>
                <td>Min:1.0</td>
              </tr>
              <tr>
                <td>8</td>
                <td>Bulk Density</td>
                <td>
                  t/m<sup>3</sup>
                </td>
                <td>Min: 1.30</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </Table>
  );
};

export default ProductTable;

const Table = styled.div`
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  background-color: ${(props) => props.theme.colors.secondary};

  .productTableContainer {
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 3rem;
    overflow: hidden;
  }

  .productTableContainer h1 {
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  .productSpec {
    border-collapse: collapse;
    font-family: "Josefin Sans", sans-serif;
  }
  .productSpec td,
  .productSpec th {
    border: none;
    text-align: left;
    padding: 8px;
  }

  .productSpec th {
    color: ${(props) => props.theme.colors.caption};
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 600;
  }
  .productSpec td {
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 600px) {
    .productTableContainer {
      padding: 0 1rem;
      overflow-x: scroll;
    }

    .productTableContainer h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
      /* color: rgb(13, 24, 86); */
    }

    .productSpec th {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    .productSpec td {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
    .productSpec {
      width: 600px;
    }
  }
`;
