import React from "react";
import styled from "styled-components";

const AboutTimeline = () => {
  return (
    <Timeline>
      <h1>Our Story</h1>
      <div className="container">
        <ul>
          <li>
            <h3 className="title">Started Journey</h3>
            <p>
              Peakward Group, founded in 1990 by Mr. Fazle Azim, CEO & Group
              Chairman, started its operations by exporting Chinese and
              Indonesian bagged cement to Bangladesh. Remarkably, it quickly
              captured a 30% market share of cement in Bangladesh
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">1990</span>
          </li>
          <li>
            <h3 className="title">500,000MT Fertilizers from Egypt</h3>
            <p>
              Peakward bought 500,000 MT Fertilizers from Egyptian Financial &
              Industrial Co. in Egypt between 1995-1998.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">1995 - 1998</span>
          </li>
          <li>
            <h3 className="title">Supply to Heidelberg</h3>
            <p>
              In the year 2000, Peakward supplied 500,000Mts of clinker to HC
              Trading for their Bangladesh plant. In 1998 Peakward had supplied
              bulk cement for Heidelberg's terminal in Egypt.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2000</span>
          </li>
          <li>
            <h3 className="title">Clinker Shipment from China to UAE</h3>
            <p>
              COA for two Panamax vessels per month of Clinker from China to
              UAE.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2006</span>
          </li>
          <li>
            <h3 className="title">Supplied GBFS from China and Japan to UAE</h3>
            <p>
              Peakward has supplied 100,000 MT of GBFS from China and Japan to
              Sharjah Cement factory in UAE between February-April 2007.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2007</span>
          </li>

          <li>
            <h3 className="title">Leading supplier in UAE</h3>
            <p>
              Peakward emerged as a prominent supplier of cementitious raw
              materials and particularly Slag, in the Middle East, supplying
              millions of tons of bulk commodities annually during that period.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2006-2009</span>
          </li>

          <li>
            <h3 className="title">
              Peakward Introduces Vietnam to the Export Market.
            </h3>
            <p>
              Peakward Played a pioneering role to bring the Vietnam cement
              industry to the world export market. 3 core developments paved the
              way:
            </p>
            <p>
              &#x2022; Peakward - Vissai Group signed a Technical and Global
              Marketing Collaboration Agreement to introduce Vissai's products
              to the international export market.
            </p>
            <p>
              &#x2022; Peakward spearheaded a brand new shipping framework,
              shifting from purely jetty loading to anchorage loading.
            </p>
            <p>
              &#x2022; Peakward was the first to load Panamax vessel in Vientam.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2010</span>
          </li>

          <li>
            <h3 className="title">Environmentally Friendly SCM</h3>
            <p>
              Peakward introduced Indonesian NATURAL POZZOLAN to the global
              cement industry. Pozzolan is a 100% ALL-NATURAL environmentally
              friendly ash with ZERO CARBON footprint. It can replace Slag/Fly
              Ash in cement production and reduce overall costs.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2014</span>
          </li>

          <li>
            <h3 className="title">Clinker from Saudi Arabia to Bangladesh</h3>
            <p>
              Peakward was awarded a 1.5-million-ton clinker contract with a
              Saudia Arabia producer.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2018</span>
          </li>

          <li>
            <h3 className="title">
              Largest Oman Gypsum Shipment to Bangladesh
            </h3>
            <p>
              During the Peak of COVID-19, Peakward carried the largest natural
              gypsum shipment from Oman to Bangladesh. Approximately 80,000MT in
              a single shipment.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2020</span>
          </li>

          <li>
            <h3 className="title">Record Breaking Volumes Shipped</h3>
            <p>Peakward supplied over 3 million tons of products, globally.</p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2022</span>
          </li>

          <li>
            <h3 className="title">Peakward Continues to Thrive</h3>
            <p>
              Peakward's contribution to society led to the increase in
              activities for CSR initiatives as well as the implementation of
              PeakFUND. A platform that helps recognize social organizations to
              project their activities to global donors.
            </p>
            <p>
              &#x2022; 2 new clinker markets to our portfolio - IVORY COAST &
              COLOMBIA.
            </p>
            <p>
              &#x2022; A milestone for Peakward for being the first to introduce
              Saudia Arabia clinker to Colombia. In ULTRACEM's semi-annual
              supplier evaluation, Peakward received a result of 4.4, above the
              highest-ranking point of 3.9.
            </p>
            <p>
              &#x2022; A comment made by ULTRACEM: "HIGH 4.4: We express our
              most sincere congratulations and thanks for your commitment and
              attention to compliance with us."
            </p>
            <p>&#x2022; 300,000 MT OF NATURAL GYPSUM SUPPLIED IN 3 MONTHS.</p>
            <p>
              &#x2022; 5 consecutive gypsum shipments from Oman to Vietnam,
              increasing Peakward's market share in Vietnam as one of the
              largest suppliers.
            </p>
            {/* <a href="#">Read More </a> */}
            <span className="circle"></span>
            <span className="date">2023</span>
          </li>
        </ul>
      </div>
    </Timeline>
  );
};

export default AboutTimeline;

const Timeline = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  row-gap: 8rem;

  h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
  }
  .container {
    width: 70%;
    height: auto;
    position: relative;
  }
  .container ul::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.serviceBorder};
  }
  .container ul {
    list-style: none;
  }
  .container ul li {
    width: 50%;
    height: auto;
    padding: 15px 20px;
    background-color: ${(props) => props.theme.colors.default};
    margin-bottom: 30px;
    border-radius: 10px;
    /* box-shadow: 4px 4px 25px rgba(51, 51, 51, 0.192); */
    border: 2px solid ${(props) => props.theme.colors.serviceBorder};
    position: relative;
    z-index: 99;
  }
  .container ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
  }
  .container ul li:nth-child(odd) .date {
    right: 20px;
  }
  .container ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
  }
  .container ul li:nth-child(7) {
    margin-bottom: 0;
  }
  .container ul li .title {
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
  }
  ul li p {
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    color: ${(props) => props.theme.colors.primary};
    margin: 7px 0;
    font-family: "Open Sans", sans-serif;
  }
  /* ul li a {
    font-size: 15px;
    color: rgb(106, 6, 236);
    text-decoration: none;
  } */
  ul li .date {
    position: absolute;
    top: -45px;
    width: 135px;
    height: 35px;
    border-radius: 20px;
    color: ${(props) => props.theme.colors.default};
    background-color: rgb(13, 24, 86);
    display: grid;
    place-items: center;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-family: "Josefin Sans", sans-serif;
  }
  .container ul li .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.serviceBorder};
    position: absolute;
    top: 0;
  }
  .container ul li .circle::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .container ul li:nth-child(odd) .circle {
    right: -30px;
    transform: translate(50%, -50%);
  }
  .container ul li:nth-child(even) .circle {
    left: -30px;
    transform: translate(-50%, -50%);
  }

  /*  Media Query Started  */

  @media screen and (max-width: 1224px) {
    .container {
      width: 85%;
    }
  }

  @media screen and (max-width: 993px) {
    .container {
      width: 80%;
      transform: translateX(15px);
    }
    .container ul::after {
      left: -30px;
    }
    .container ul li {
      width: 100%;
      float: none;
      clear: none;
      margin-bottom: 80px;
    }
    .container ul li:nth-child(odd) {
      text-align: left;
      transform: translateX(0);
    }
    .container ul li:nth-child(odd) .date {
      left: 20px;
    }
    .container ul li:nth-child(odd) .circle {
      left: -30px;
      transform: translate(-50%, -50%);
    }
    .container ul li:nth-child(even) {
      transform: translateX(0);
    }

    h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }
    .container ul li .title {
      font-size: ${(props) => props.theme.fontSizes.h6};
    }
  }
`;
