import React from "react";
import styled from "styled-components";
import bg from "../assets/img/news/news-bg.jpg";

const NewsBanner = () => {
  return (
    <NewsBannerStyle>
      <div className="overlay"></div>
      <div className="text">
        <h1>News & Events</h1>
        <p>Find out news and events of Peakward</p>
      </div>
    </NewsBannerStyle>
  );
};

export default NewsBanner;

const NewsBannerStyle = styled.div`
  min-height: 50vh;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.default};
  z-index: 1;
  position: relative;
  .overlay {
    background: ${(props) => props.theme.colors.bannerOverlay};

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .text {
    z-index: 3;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    font-family: "Josefin Sans", sans-serif;
  }

  .text h1 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    line-height: ${(props) => props.theme.lineHeights.h2};
    font-weight: 700;
  }

  .text p {
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: justify;
  }

  @media only screen and (max-width: 1400px) {
    .text {
      width: 70%;
      text-align: justify;
    }
    /* .text h1 {
      font-size: 3rem;
    } */
  }

  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
    padding: 10rem 1rem;

    .text {
      width: 100%;
      text-align: center;
      row-gap: 0;
    }

    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
      text-align: center;
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
      text-align: center;
    }
  }
`;
