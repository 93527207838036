import React, { useState } from "react";
import styled from "styled-components";
import vessel1 from "../assets/img/vessels/vessel1.png";
import vessel2 from "../assets/img/vessels/vessel2.png";
import vessel3 from "../assets/img/vessels/vessel3.png";
import vessel4 from "../assets/img/vessels/vessel4.png";

const Vessel = React.memo(() => {
  const [size1, setSize1] = useState(true);
  const [size2, setSize2] = useState(false);
  const [size3, setSize3] = useState(false);
  const [size4, setSize4] = useState(false);
  return (
    <VesselStyle>
      <div className="container">
        <div className="text">
          <h1>Vessels We Operate</h1>
          <p>
            Peakward Group operates a variety of dry bulk carriers. These
            vessels are typically equipped with cranes, which enables them to
            operate more efficiently and independently, instead of depending on
            port facilities.
          </p>
        </div>
        <div className="btn-container">
          <button
            onClick={() => {
              setSize1(true);
              setSize2(false);
              setSize3(false);
              setSize4(false);
            }}
            id={size1 ? "show-border" : ""}
          >
            Handysize
          </button>
          <button
            onClick={() => {
              setSize1(false);
              setSize2(true);
              setSize3(false);
              setSize4(false);
            }}
            id={size2 ? "show-border" : ""}
          >
            Supramax
          </button>
          <button
            onClick={() => {
              setSize1(false);
              setSize2(false);
              setSize3(true);
              setSize4(false);
            }}
            id={size3 ? "show-border" : ""}
          >
            Ultramax
          </button>
          <button
            onClick={() => {
              setSize1(false);
              setSize2(false);
              setSize3(false);
              setSize4(true);
            }}
            id={size4 ? "show-border" : ""}
          >
            Panamax
          </button>
        </div>

        {size1 && (
          <div className="vessel-details" id="vessel-1">
            <div className="cargo">
              <h1>Main Cargo</h1>
              <ul>
                <li>Cement</li>
                <li>Coal</li>
                <li>Fertilizer</li>
                <li>Grain</li>
                <li>Iron ore</li>
                <li>Minerals</li>
                <li>Scrap</li>
                <li>Steel</li>
                <li>Sugar</li>
              </ul>
            </div>
            <div className="customer">
              <h1>Customers</h1>
              <ul>
                <li>Cement manufacturers</li>
                <li>Fertilizer manufacturers</li>
                <li>Grain merchants</li>
                <li>Mining companies</li>
                <li>Power companies</li>
                <li>Steelworks</li>
                <li>Sugar producers</li>
                <li>Trading houses</li>
              </ul>
            </div>
            <div className="size">
              <h1>Size</h1>
              <p>25,000 to 49,000 DWT</p>
              <img src={vessel1} width="650" height="190" alt="vessel" />
            </div>
          </div>
        )}

        {size2 && (
          <div className="vessel-details" id="vessel-2">
            <div className="cargo">
              <h1>Main Cargo</h1>
              <ul>
                <li>Cement</li>
                <li>Coal</li>
                <li>Fertilizer</li>
                <li>Grain</li>
                <li>Iron ore</li>
                <li>Petcoke</li>
                <li>Steel</li>
                <li>Sugar</li>
              </ul>
            </div>
            <div className="customer">
              <h1>Customers</h1>
              <ul>
                <li>Cement manufacturers</li>
                <li>Fertilizer manufacturers</li>
                <li>Grain merchants</li>
                <li>Mining companies</li>
                <li>Power companies</li>
                <li>Steelworks</li>
                <li>Sugar producers</li>
                <li>Trading houses</li>
              </ul>
            </div>
            <div className="size">
              <h1>Size</h1>
              <p>50,000 to 60,000 DWT</p>
              <img src={vessel2} width="650" height="190" alt="vessel" />
            </div>
          </div>
        )}
        {size3 && (
          <div className="vessel-details" id="vessel-3">
            <div className="cargo">
              <h1>Main Cargo</h1>
              <ul>
                <li>Cement</li>
                <li>Coal</li>
                <li>Fertilizer</li>
                <li>Grain</li>
                <li>Iron ore</li>
                <li>Petcoke</li>
                <li>Steel</li>
                <li>Sugar</li>
              </ul>
            </div>
            <div className="customer">
              <h1>Customers</h1>
              <ul>
                <li>Cement manufacturers</li>
                <li>Fertilizer manufacturers</li>
                <li>Grain merchants</li>
                <li>Mining companies</li>
                <li>Power companies</li>
                <li>Steelworks</li>
                <li>Sugar producers</li>
                <li>Trading houses</li>
              </ul>
            </div>
            <div className="size">
              <h1>Size</h1>
              <p>60,000 to 65,000 DWT</p>
              <img src={vessel3} width="650" height="190" alt="vessel" />
            </div>
          </div>
        )}

        {size4 && (
          <div className="vessel-details" id="vessel-4">
            <div className="cargo">
              <h1>Main Cargo</h1>
              <ul>
                <li>Iron ore</li>
                <li>Coal</li>
                <li>Grain</li>
                <li>Bauxite</li>
                <li>Steels</li>
                <li>Fertilizer</li>
              </ul>
            </div>
            <div className="customer">
              <h1>Customers</h1>
              <ul>
                <li>Steelworks</li>
                <li>Mining companies</li>
                <li>Power companies</li>
                <li>Grain merchants</li>
                <li>Trading houses</li>
              </ul>
            </div>
            <div className="size">
              <h1>Size</h1>
              <p>70,000 to 99,000 DWT</p>
              <img src={vessel4} width="650" height="190" alt="vessel" />
            </div>
          </div>
        )}
      </div>
    </VesselStyle>
  );
});

export default Vessel;

const VesselStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-width: 1200px;
    margin: auto;
    padding: 5rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .container h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    color: ${(props) => props.theme.colors.primary};
  }

  .container p {
    max-width: 800px;
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    color: ${(props) => props.theme.colors.primary};
  }

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .size img {
    width: 100%;
    margin-top: 2rem;
  }

  .size p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 400;
  }

  .vessel-details {
    display: flex;
    column-gap: 5rem;
  }

  .vessel-details h1 {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
  }

  ul {
    list-style: none;
  }

  ul li {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.small};
    color: ${(props) => props.theme.colors.primary};
  }

  .btn-container {
    display: flex;
    column-gap: 2rem;
  }

  .btn-container button {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    border: none;
    background-color: transparent;
    color: ${(props) => props.theme.colors.caption};
    padding-bottom: 4px 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
  }

  .cargo {
    min-width: 130px;
  }
  .customer {
    min-width: 250px;
  }

  #show-border {
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.primary};
  }

  @media only screen and (max-width: 600px) {
    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
    .cargo {
      min-width: unset;
    }
    .cargo h1,
    .customer h1,
    .size h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
    }
    .customer {
      min-width: unset;
    }

    .vessel-details {
      flex-wrap: wrap;
      row-gap: 2rem;
    }
    .size img {
      width: 100%;
      height: auto;
      margin-top: 2rem;
    }
    .btn-container {
      column-gap: 1rem;
    }

    .btn-container button {
      font-size: ${(props) => props.theme.fontSizes.p};
    }

    ul li {
      font-size: ${(props) => props.theme.fontSizes.small};
    }

    .size p {
      font-size: ${(props) => props.theme.fontSizes.p};
    }
  }
`;
