import Partner1 from "../assets/img/partners/partner1.png";
import Partner2 from "../assets/img/partners/partner2.png";
import Partner3 from "../assets/img/partners/partner3.png";
import Partner4 from "../assets/img/partners/partner4.png";
import Partner5 from "../assets/img/partners/partner5.png";
import Partner6 from "../assets/img/partners/partner6.png";
import Partner7 from "../assets/img/partners/partner7.png";
import Partner8 from "../assets/img/partners/partner8.png";
import Partner9 from "../assets/img/partners/partner9.png";
import Partner10 from "../assets/img/partners/partner10.png";
import Partner11 from "../assets/img/partners/partner11.png";
import Partner12 from "../assets/img/partners/partner12.png";
import Partner13 from "../assets/img/partners/partner13.png";
import Partner14 from "../assets/img/partners/partner14.png";
import Partner15 from "../assets/img/partners/partner15.png";
import Partner16 from "../assets/img/partners/partner16.png";
import Partner17 from "../assets/img/partners/partner17.png";
// import Partner18 from "../assets/img/partners/partner18.svg";
import styled from "styled-components";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Navigation, Pagination } from "swiper";

const Partners = () => {
  return (
    <PartnersStyle>
      <div className="container">
        <h1>Our Partners</h1>
        <div className="partner">
          <Swiper
            className="slider"
            spaceBetween={0}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            // slidesPerView={4.2}
            breakpoints={{
              // 600: {
              //   width: 600,
              //   slidesPerView: 2,
              //   spaceBetween: 100,
              // },
              1200: {
                width: 1200,
                slidesPerView: 2.5,
              },
              1400: {
                width: 1400,
                slidesPerView: 3.2,
              },
              1521: {
                width: 1521,
                slidesPerView: 4.5,
                spaceBetween: 20,
              },

              1900: {
                width: 1900,
                slidesPerView: 5.5,
                spaceBetween: 20,
              },
            }}
            style={{
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            <SwiperSlide>
              <CardStyled>
                <img src={Partner1} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner2} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner3} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner4} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner5} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner6} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner7} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner8} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            {/* <SwiperSlide>
              <CardStyled>
                <img src={Partner9} alt="partner company" />
              </CardStyled>
            </SwiperSlide> */}
            <SwiperSlide>
              <CardStyled>
                <img src={Partner10} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner11} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner12} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner13} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide>
              <CardStyled>
                <img src={Partner14} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            {/* <SwiperSlide>
              <CardStyled>
                <img src={Partner15} alt="partner company" />
              </CardStyled>
            </SwiperSlide> */}
            <SwiperSlide>
              <CardStyled>
                <img src={Partner16} alt="partner company" />
              </CardStyled>
            </SwiperSlide>
            {/* <SwiperSlide>
              <CardStyled>
                <img src={Partner17} alt="partner company" />
              </CardStyled>
            </SwiperSlide> */}
            {/* <SwiperSlide>
              <CardStyled>
                <img src={Partner18} alt="partner company" />
              </CardStyled>
            </SwiperSlide> */}
          </Swiper>
        </div>
        <div className="slider">
          <Swiper
            className="slides"
            breakpoints={{
              600: {
                width: 600,
                slidesPerView: 1.2,
              },
            }}
            modules={[Navigation, Pagination, Autoplay]}
            // spaceBetween={0}
            slidesPerView={1.5}
            loop={true}
            autoplay
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner1} alt="Slide 1" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner2} alt="Slide 2" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner3} alt="Slide 3" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner4} alt="Slide 4" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner5} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner6} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner7} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner8} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner9} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner10} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner11} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner12} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner13} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner14} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner15} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner16} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner17} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide>
            {/* <SwiperSlide className="swiper">
              <CardStyled>
                <img src={Partner18} alt="Slide 5" />
              </CardStyled>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </PartnersStyle>
  );
};

export default Partners;

const PartnersStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 50vh;
    align-items: center;
    row-gap: 4rem;
    padding-top: 7rem;
    padding-bottom: 0;
  }
  .container h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h3};
  }

  .container .partner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img {
    width: 100%;
    filter: grayscale();
    max-width: 250px;
  }

  .slides {
    display: none;
  }

  @media only screen and (max-width: 1600px) {
    .container {
      /* max-width: 1200px; */
      /* padding: 10rem 2rem; */
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 5rem 1rem;
      width: 100%;
    }
    /* .partner {
      flex-direction: column;
      row-gap: 2rem;
    } */

    .container .partner {
      display: none;
    }

    .container h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
    }
    .slides {
      display: block;
      width: 350px;
    }
    .swiper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const CardStyled = styled.div`
  /* border: 1px solid red; */
  min-height: 200px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1rem;
  /* width: fit-content; */
`;
