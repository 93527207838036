import React from "react";
import styled from "styled-components";
import Mission from "../assets/img/about/mission.svg";
import Vission from "../assets/img/about/vission.svg";

const AboutGoal = () => {
  return (
    <Goal>
      <div className="container">
        <div className="mission">
          <div className="text">
            <h1>Mission</h1>
            <ul>
              <li>
                Be a valued and trusted partner in our business community.
              </li>
              <li>Setting the highest standards of customer satisfaction.</li>
              <li>
                Sustaining our reputation as a reliable, customer-oriented,
                dynamic player in the market.
              </li>
              <li>Secure the strongest competitive position in our markets.</li>
              <li>
                Contribute our part to help reduce carbon footprint for our
                future generation.
              </li>
            </ul>
          </div>
          <div className="image">
            <img src={Mission} alt="" />
          </div>
        </div>

        <div className="vission">
          <div className="text">
            <h1>Vission</h1>
            <p>
              To be a trusted global leader in the bulk commodities trade
              industry, by consistently delivering excellence across all aspects
              of our operations while upholding the highest ethical standards.
            </p>
          </div>
          <div className="image">
            <img src={Vission} alt="" />
          </div>
        </div>
      </div>
    </Goal>
  );
};

export default AboutGoal;

const Goal = styled.div`
  min-height: 100vh;
  background-color: ${(props) => props.theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    row-gap: 10rem;
  }

  .mission,
  .vission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    gap: 5rem;
  }

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    color: ${(props) => props.theme.colors.primary};
  }

  .text h1 {
    font-size: ${(props) => props.theme.fontSizes.h2};
    /* font-size: 2.5rem; */
    font-family: "Josefin Sans", sans-serif;
    font-weight: 600;
  }
  .text p {
    font-size: ${(props) => props.theme.fontSizes.p};
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    line-height: ${(props) => props.theme.lineHeights.p};
  }

  .text ul {
    padding-left: 1rem;
  }

  .text ul li {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 400;
  }

  .image img {
    width: 250px;
  }

  @media only screen and (max-width: 1400px) {
    padding: 5rem 0;
    .container {
      row-gap: 5rem;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 5rem 0;
    .mission,
    .vission {
      flex-direction: column;
      width: 90%;
      gap: 5rem;
    }
    .container {
      row-gap: 6rem;
    }

    .text h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
    }

    .text p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }

    .text ul li {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;
