import React from "react";
import styled from "styled-components";
import news1 from "../assets/img/blog/blog1.jpg";
import news2 from "../assets/img/blog/blog2.jpg";
import news3 from "../assets/img/blog/blog3.jpg";
import news4 from "../assets/img/blog/blog4.jpg";
import news5 from "../assets/img/blog/blog5.jpg";
import news6 from "../assets/img/blog/blog6.png";
import news7 from "../assets/img/blog/blog7.png";
import news8 from "../assets/img/blog/blog8.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const NewsList = () => {
  return (
    <NewsListStyle>
      {/* <div className="title">
        <h1>News & Events</h1>
      </div> */}
      <div className="container">
        <div className="news-section">
          <div className="news-item">
            <img src={news6} alt="news" />
            <div className="text">
              <div className="date">March 3, 2023</div>
              <div className="title">
                <Link to="/news/6">
                  PEAKWARD GROUP Joins INTERCEM DUBAI 2023: Paving the Way for
                  Innovation and Collaboration in the Global Industry
                </Link>
              </div>
            </div>
            <div className="description">
              <p>
                In a resounding triumph, PEAKWARD GROUP, a renowned industry
                leader, successfully participated in the highly anticipated
                INTERCEM DUBAI 2023. This momentous event, held recently,
                brought together a diverse delegation from Hong Kong, India,
                UAE, and Turkey, showcasing the company's commitment to global
                collaboration and innovation......
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news7} alt="news" />
            <div className="text">
              <div className="date">January 1, 2023</div>
              <div className="title">
                <Link to="/news/7">
                  Chairman Fazle Azim Inspires Peakward Bangladesh Family at
                  Glorious New Year Celebration
                </Link>
              </div>
            </div>
            <div className="description">
              <p>
                This glorious morning, resonating with joy and anticipation, our
                esteemed Peakward Bangladesh family gathered to celebrate the
                advent of a brand new year. The air was filled with an electric
                sense of excitement and camaraderie as we eagerly awaited the
                arrival of a special guest who would grace our event with his
                esteemed presence......
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news8} alt="news" />
            <div className="text">
              <div className="date">November 3, 2023</div>
              <div className="title">
                <Link to="/news/8">
                  Peakward Family Celebrates Exceptional Achievements: Best
                  Performance Awards and Employee Welfare Fund Unveiled
                </Link>
              </div>
            </div>
            <div className="description">
              <p>
                It was a truly momentous and joyous day for the entire Peakward
                Family as we gathered to celebrate the exceptional achievements
                of our top performers at the Bangladesh Office. In a grand
                ceremony filled with excitement and anticipation, five
                outstanding individuals were honored with the prestigious Best
                Performance Awards, recognizing their dedication, hard work, and
                remarkable contributions to the growth and success of Peakward
                Group......
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news1} alt="news" />
            <div className="text">
              <div className="date">DECEMBER 18, 2019</div>
              <div className="title">
                <Link to="/news/1">Intercem Dhaka 2019</Link>
              </div>
            </div>
            <div className="description">
              <p>
                Intercem Dhaka 2019 hosted by Peakward held at Le Meridien from
                4th to 6th November. It was an eventful experience with all the
                participation from industries in Bangladesh and all around the
                globe. A cultural performance was held by the special child of
                the PFDA- Vocational Training Center. Peakward is proud to
                facilitate PFDA at Intercem and expresses full support for their
                noble cause...
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news2} alt="news" />
            <div className="text">
              <div className="date">OCTOBER 25, 2018</div>
              <div className="title">
                <Link to="/news/2">Intercem Egypt 2018</Link>
              </div>
            </div>
            <div className="description">
              <p>
                We, PEAKWARD, attended the Cairo Intercem on 17-19 September
                2018 and had the chance to meet business professionals from the
                cement industry all around the globe. It has been a pleasure to
                meet with various multinational trading companies which have
                enlarged our business scope more definitely...
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news3} alt="news" />
            <div className="text">
              <div className="date">MAY 12, 2018</div>
              <div className="title">
                <Link to="/news/3">Recent Shipments of Peakward Group</Link>
              </div>
            </div>
            <div className="description">
              <p>
                Recent Shipments of Peakward Group Peakward’s Trading arm
                focuses mainly on commodities such as Cement, Clinker, Gypsum
                (Natural & Chemical, Limestone, BF Slag, Pozzolana (Volcanic
                ash), Stone & Aggregates, Fly Ash, Coal, Fertilizer and iron ore
                that are essential in supporting global rapid growth. Peakward’s
                Global Trading operation is one of the largest and most widely
                recognized in the industry...
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news4} alt="news" />
            <div className="text">
              <div className="date">MAY 12, 2018</div>
              <div className="title">
                <Link to="/news/4">Cement Knowledge Sharing Conference</Link>
              </div>
            </div>
            <div className="description">
              <p>
                Cement Knowledge Sharing Conference “Cement Knowledge Sharing
                Conference” organized by Peakward Group, Hong Kong and SUNNSY
                Cement Group of China is held on October 08, 2015 at Royal Park
                Residence Hotel, Dhaka, Bangladesh....
              </p>
            </div>
          </div>

          <div className="news-item">
            <img src={news5} alt="news" />
            <div className="text">
              <div className="date">APRIL 18, 2018</div>
              <div className="title">
                <Link to="/news/5">
                  Bangladesh community living in Vietnam met with honorable
                  president of Bangladesh
                </Link>
              </div>
            </div>
            <div className="description">
              <p>
                Chairman of Peakward Group Mr. Fazle Azim and Bangladeshi
                Community living in Vietnam met with Honorable President of
                Bangladesh Mr. Abdul Hamid at Hanoi of Vietnam on 09 August
                2015. The visit of Bangladesh president is expected to further
                deepen the cordial relations and strengthen the ties of
                friendship and trade and investment relations between Bangladesh
                and the Southeast Asian country.....
              </p>
            </div>
          </div>
        </div>
        <div className="search-section">
          <div className="search">
            <h3>Search</h3>
            <input type="text" />
          </div>

          <div className="keywords">
            <button>News</button>
            <button>Articles</button>
          </div>
        </div>
      </div>
    </NewsListStyle>
  );
};

export default NewsList;

const NewsListStyle = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5rem;
  padding: 5rem 0;

  .container {
    width: 1300px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  /* 
  .title h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: 2.5rem;
  } */

  .news-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .news-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 2rem 0;
    max-width: 800px;
    &:first-child {
      padding: 0 0 2rem 0;
    }
  }

  .news-item img {
    /* border-radius: 0.5rem; */
    /* max-width: 250px;
    min-height: 140px; */
    width: 100%;
  }

  .text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
  }

  .date {
    /* color: #007aff; */
    color: ${(props) => props.theme.colors.caption};
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 700;
  }

  .title a {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 600;
    line-height: 2rem;
    text-decoration: none;
  }
  .description {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 400;
  }

  .links {
    padding-left: 4rem;
  }

  /* .links button {
    border: none;
    color: ${(props) => props.theme.colors.highlight};
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background: none;
    cursor: pointer;
  } */

  .search-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .keywords {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .search h3 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h6};
  }

  .search {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .search input {
    border-radius: 0.5rem;
    height: 2rem;
    width: 250px;
    font-size: ${(props) => props.theme.fontSizes.p};
    padding: 1.2rem 1rem;
    font-family: "Open Sans", sans-serif;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  .keywords button {
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0.8rem 1.5rem;
    border-radius: 1.5rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.default};
    border: none;
    width: fit-content;
    min-width: 100px;
    font-size: ${(props) => props.theme.fontSizes.p};
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column-reverse;
      width: 90%;
      row-gap: 5rem;
    }

    /* .title h1 {
      font-size: 2rem;
    } */
    .news-item {
      flex-direction: column;
      /* row-gap: 1rem; */
    }

    .text {
      display: flex;
      flex-direction: column;
      /* row-gap: 1rem; */
      min-width: unset;
      align-items: center;
      text-align: center;
    }

    .title {
      font-size: ${(props) => props.theme.fontSizes.h6};
    }

    .news-section {
      align-items: center;
    }

    /* .links {
      padding-left: unset;
    } */
    .description p {
      text-align: center;
    }

    .search,
    .keywords {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
