import { Link } from "react-router-dom";
import styled from "styled-components";
import Product1 from "../assets/img/products/product1.png";
import Product2 from "../assets/img/products/product2.png";
import Product3 from "../assets/img/products/product3.png";
import Product4 from "../assets/img/products/product4.png";
import Product5 from "../assets/img/products/product5.png";
import Product6 from "../assets/img/products/product6.png";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Products = (props) => {
  return (
    <ProductsStyle bg={props.bg}>
      <div className="container">
        <div className="titles">
          <h1>Our Products</h1>
          {/* <p>
            We provide a diverse section of products in the cementicious domain.
          </p> */}
        </div>
        <div className="gallary">
          <div className="product">
            <Link to="/clinker">
              <div className="image-container">
                <img src={Product1} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>CLINKER</h1>
            </Link>
          </div>
          <div className="product">
            <Link to="/limestone">
              <div className="image-container">
                <img src={Product2} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>LIMESTONE</h1>
            </Link>
          </div>
          <div className="product">
            <Link to="/gypsum">
              <div className="image-container">
                <img src={Product3} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>GYPSUM</h1>
            </Link>
          </div>
          <div className="product">
            <Link to="/pozzolana">
              <div className="image-container">
                <img src={Product4} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>POZZOLANA</h1>
            </Link>
          </div>
          <div className="product">
            <Link to="/slag">
              <div className="image-container">
                <img src={Product5} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>SLAG</h1>
            </Link>
          </div>
          <div className="product">
            <Link to="/construction_stone">
              <div className="image-container">
                <img src={Product6} alt="product" />
                <div className="image-overlay"></div>
              </div>
              <h1>
                CONSTRUCTION <br /> STONE
              </h1>
            </Link>
          </div>
        </div>
        <div className="slider">
          <Swiper
            className="slides"
            breakpoints={{
              600: {
                width: 600,
                slidesPerView: 1.5,
              },
            }}
            modules={[Navigation, Pagination]}
            spaceBetween={-50}
            slidesPerView={1.5}
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSwiper={(swiper) => console.log(swiper)}
            // onSlideChange={() => console.log("slide change")}
          >
            <SwiperSlide>
              <Link to="/clinker">
                <img src={Product1} alt="Slide 1" />
                <div className="slide-content">
                  <h3>Clinker</h3>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/limestone">
                <img src={Product2} alt="Slide 2" />
                <div className="slide-content">
                  <h3>Limestone</h3>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/gypsum">
                <img src={Product3} alt="Slide 3" />
                <div className="slide-content">
                  <h3>Gypsum</h3>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/pozzolana">
                <img src={Product4} alt="Slide 4" />
                <div className="slide-content">
                  <h3>Pozzolana</h3>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/slag">
                <img src={Product5} alt="Slide 5" />
                <div className="slide-content">
                  <h3>Slag</h3>
                </div>
              </Link>
            </SwiperSlide>
            <SwiperSlide>
              <Link to="/construction_stone">
                <img src={Product6} alt="Slide 6" />
                <div className="slide-content">
                  <h3>Construction Stone</h3>
                </div>
              </Link>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </ProductsStyle>
  );
};

export default Products;

const ProductsStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};

  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* min-height: 80vh; */
    row-gap: 5rem;
    padding: 5rem 0;
  }

  .gallary {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }

  .product {
    position: relative;
    padding: 1rem 0.5rem 0 0.5rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    overflow: hidden;
    width: 200px;
    &:hover img {
      transform: scale(1.2);
    }
    &:hover .image-overlay {
      opacity: 0.3;
    }
  }

  .product:hover {
    background-color: ${(props) => props.theme.colors.default};
  }

  .image-container {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
  }

  .product img {
    width: 100%;
    transition: 0.3s ease;
  }

  .product h1 {
    position: absolute;
    bottom: 1rem;
    right: 0;
    left: 0;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 300;
    color: ${(props) => props.theme.colors.default};
    font-family: "Josefin Sans", sans-serif;
    transition: transform 0.3s ease;
  }

  .product:hover h1 {
    transform: translateY(-50%);
    font-weight: 700;
  }

  /* .product:last-child:hover h1 {
    transform: translateY(-200%);
    font-weight: 700;
  } */

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.colors.primary};
    backdrop-filter: blur(5px);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .titles {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: ${(props) => props.theme.colors.primary};
  }

  .titles h1 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
    font-family: "Josefin Sans", sans-serif;
  }

  .titles p {
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-family: "Open Sans", sans-serif;
  }

  .slides {
    display: none;
  }

  @media only screen and (max-width: 1600px) {
    .container {
      /* max-width: 1200px; */
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .product {
      width: unset;
    }
    .product img {
      width: auto;
    }
    .product h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }
  }

  @media only screen and (max-width: 600px) {
    .container .gallary {
      flex-direction: column;
      row-gap: 1rem;
    }
    .product img {
      width: 300px;
    }
    .container .titles {
      padding: 0 1rem;
    }

    .titles h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .titles p {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    .gallary {
      display: none;
    }

    .slides {
      display: block;
      width: 350px;
    }

    .slides h3 {
      font-family: "Open Sans", sans-serif;
      font-size: ${(props) => props.theme.fontSizes.p};

      font-weight: 700;
      color: ${(props) => props.theme.colors.primary};
    }
    .slides a {
      text-decoration: none;
    }
  }
`;
