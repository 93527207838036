import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LatestNews = () => {
  return (
    <NewsStyle>
      <div className="container">
        <h1>Latest news</h1>
        <div className="news-list">
          <div className="news">
            <p>3 March 2023</p>
            <div>
              <Link to="/news/6">
                <h1>
                  PEAKWARD GROUP Joins INTERCEM DUBAI 2023: Paving the Way for
                  Innovation and Collaboration in the Global Industry
                </h1>
              </Link>
              <Link to="/news/6">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr />

          <div className="news">
            <p>1 January 2023</p>
            <div>
              <Link to="/news/7">
                <h1>
                  Chairman Fazle Azim Inspires Peakward Bangladesh Family at
                  Glorious New Year Celebration
                </h1>
              </Link>
              <Link to="/news/7">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr />

          <div className="news">
            <p>3 November 2023</p>
            <div>
              <Link to="/news/8">
                <h1>
                  Peakward Family Celebrates Exceptional Achievements: Best
                  Performance Awards and Employee Welfare Fund Unveiled
                </h1>
              </Link>
              <Link to="/news/8">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr />

          <div className="news">
            <p>18 DECEMBER 2019</p>
            <div>
              <Link to="/news/1">
                <h1>Intercem Dhaka 2019</h1>
              </Link>
              <Link to="/news/1">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr />

          <div className="news">
            <p>25 OCTOBER 2018</p>
            <div>
              <Link to="/news/2">
                <h1>Intercem Egypt 2018</h1>
              </Link>
              <Link to="/news/2">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr />

          {/* <div className="news">
            <p>12 MAY 2018</p>
            <div>
              <Link to="/news/3">
                <h1>Recent Shipments of Peakward Group</h1>
              </Link>
              <Link to="/news/3">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr /> */}

          {/* <div className="news">
            <p>12 MAY 2018</p>
            <div>
              <Link to="/news/4">
                <h1>Cement Knowledge Sharing Conference</h1>
              </Link>
              <Link to="/news/4">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr /> */}
          {/* 
          <div className="news">
            <p>18 APRIL 2018</p>
            <div>
              <h1>
                Bangladesh community living in Vietnam met with honorable
                president of Bangladesh
              </h1>
              <Link to="/news/5">
                <FontAwesomeIcon icon={faCircleArrowRight} />
              </Link>
            </div>
          </div>
          <hr /> */}
        </div>

        <Link to="/news">
          <button>View All</button>
        </Link>
      </div>
    </NewsStyle>
  );
};

export default LatestNews;

const NewsStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    max-width: 1600px;
    margin: auto;
    /* min-height: 100vh; */
    row-gap: 4rem;
    padding: 5rem 0;
    padding-bottom: 5rem;
    max-width: 1200px;
  }

  .container h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h2};
  }

  .news-list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    overflow-x: scroll;
  }

  .news-list::-webkit-scrollbar {
    display: none;
  }

  .news div {
    display: flex;
    justify-content: space-between;
  }
  .news h1 {
    max-width: 900px;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h5};
  }

  .news p {
    color: ${(props) => props.theme.colors.caption};
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  .container button {
    width: fit-content;
    padding: 1rem 2rem;
    border: none;
    border-radius: 1.5rem;
    background-color: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.p};
    font-weight: 600;
    cursor: pointer;
  }

  .news {
    height: fit-content;
    border-radius: 10px;
  }

  .news svg {
    font-size: ${(props) => props.theme.fontSizes.h4};
    color: ${(props) => props.theme.colors.primary};
    cursor: pointer;
    width: 28px;
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 0 1rem;
      row-gap: 2rem;
      min-height: 60vh;
    }

    .container h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .news-list {
      flex-direction: row;
      gap: 2rem;
    }

    .news-list hr {
      display: none;
    }

    .news {
      background-color: #d9e2fe;
      min-width: 290px;
      min-height: 250px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem;
      text-align: center;
      min-height: 280px;
    }

    .news div {
      flex-direction: column;
      row-gap: 1rem;
    }

    .news p {
      font-size: ${(props) => props.theme.fontSizes.small};
    }

    .news h1 {
      font-size: ${(props) => props.theme.fontSizes.h6};
      line-height: ${(props) => props.theme.lineHeights.h6};
    }

    .container button {
      cursor: pointer;
    }
  }
`;
