import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import news1 from "../assets/img/blog/blog1.jpg";
import news2 from "../assets/img/blog/blog2.jpg";
import news3 from "../assets/img/blog/blog3.jpg";
import news4 from "../assets/img/blog/blog4.jpg";
import news5 from "../assets/img/blog/blog5.jpg";
import news6 from "../assets/img/blog/blog6.png";
import news7 from "../assets/img/blog/blog7.png";
import news8 from "../assets/img/blog/blog8.png";
import NewsBanner from "../components/NewsBanner";

const NewsDetail = () => {
  const location = useLocation().pathname;
  const [newsId, setNewsId] = useState();

  useEffect(() => {
    if (location === "/news/1") {
      setNewsId(1);
    } else if (location === "/news/2") {
      setNewsId(2);
    } else if (location === "/news/3") {
      setNewsId(3);
    } else if (location === "/news/4") {
      setNewsId(4);
    } else if (location === "/news/5") {
      setNewsId(5);
    } else if (location === "/news/6") {
      setNewsId(6);
    } else if (location === "/news/7") {
      setNewsId(7);
    } else if (location === "/news/8") {
      setNewsId(8);
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <NewsBanner />
      <NewsListStyle>
        <div className="container">
          <div className="news-section">
            <div className="news-item">
              {newsId === 1 ? (
                <img src={news1} alt="news" />
              ) : newsId === 2 ? (
                <img src={news2} alt="news" />
              ) : newsId === 3 ? (
                <img src={news3} alt="news" />
              ) : newsId === 4 ? (
                <img src={news4} alt="news" />
              ) : newsId === 5 ? (
                <img src={news5} alt="news" />
              ) : newsId === 5 ? (
                <img src={news5} alt="news" />
              ) : newsId === 6 ? (
                <img src={news6} alt="news" />
              ) : newsId === 7 ? (
                <img src={news7} alt="news" />
              ) : newsId === 8 ? (
                <img src={news8} alt="news" />
              ) : (
                ""
              )}
              <div className="text">
                {newsId === 1 ? (
                  <>
                    <div className="date">DECEMBER 18, 2019</div>
                    <div className="title">Intercem Dhaka 2019</div>
                  </>
                ) : newsId === 2 ? (
                  <>
                    <div className="date">OCTOBER 25, 2018</div>
                    <div className="title">Intercem Egypt 2018</div>
                  </>
                ) : newsId === 3 ? (
                  <>
                    <div className="date">MAY 12, 2018</div>
                    <div className="title">
                      Recent Shipments of Peakward Group
                    </div>
                  </>
                ) : newsId === 4 ? (
                  <>
                    <div className="date">MAY 12, 2018</div>
                    <div className="title">
                      Cement Knowledge Sharing Conference
                    </div>
                  </>
                ) : newsId === 5 ? (
                  <>
                    <div className="date">APRIL 18, 2018</div>
                    <div className="title">
                      Bangladesh community living in Vietnam met with honorable
                      president of Bangladesh
                    </div>
                  </>
                ) : newsId === 6 ? (
                  <>
                    <div className="date">March 3, 2023</div>
                    <div className="title">
                      PEAKWARD GROUP Joins INTERCEM DUBAI 2023: Paving the Way
                      for Innovation and Collaboration in the Global Industry
                    </div>
                  </>
                ) : newsId === 7 ? (
                  <>
                    <div className="date">January 1, 2023</div>
                    <div className="title">
                      Chairman Fazle Azim Inspires Peakward Bangladesh Family at
                      Glorious New Year Celebration
                    </div>
                  </>
                ) : newsId === 8 ? (
                  <>
                    <div className="date">November 3, 2023</div>
                    <div className="title">
                      Peakward Family Celebrates Exceptional Achievements: Best
                      Performance Awards and Employee Welfare Fund Unveiled
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="description">
                {newsId === 1 ? (
                  <p>
                    Intercem Dhaka 2019 hosted by Peakward held at Le Meridien
                    from 4th to 6th November. It was an eventful experience with
                    all the participation from industries in Bangladesh and all
                    around the globe. A cultural performance was held by the
                    special child of the PFDA- Vocational Training Center.
                    Peakward is proud to facilitate PFDA at Intercem and
                    expresses full support for their noble cause.
                  </p>
                ) : newsId === 2 ? (
                  <p>
                    We, PEAKWARD, attended the Cairo Intercem on 17-19 September
                    2018 and had the chance to meet business professionals from
                    the cement industry all around the globe. It has been a
                    pleasure to meet with various multinational trading
                    companies which have enlarged our business scope more
                    definitely.
                  </p>
                ) : newsId === 3 ? (
                  <p>
                    Recent Shipments of Peakward Group Peakward's Trading arm
                    focuses mainly on commodities such as Cement, Clinker,
                    Gypsum (Natural & Chemical, Limestone, BF Slag, Pozzolana
                    (Volcanic ash), Stone & Aggregates, Fly Ash, Coal,
                    Fertilizer and iron ore that are essential in supporting
                    global rapid growth. Peakward's Global Trading operation is
                    one of the largest and most widely recognized in the
                    industry. It is supported by a worldwide network of
                    strategically located sea port and strong relationships with
                    reliable manufacturers, suppliers/shippers around the world.
                    Please find following some recent shipment details of
                    Peakward Group: 1. Gabbro Aggregate (Crushed Stone) Vessel
                    Name: MV Ocean Glory Total Cargo: 42,600 MT Shipment : From
                    Oman to Bangladesh Shipment Date: September 11, 2016
                    Specification/Test Report: Please Visit 2. Cement Clinker
                    Vessel Name: MV Konstantinos Total Cargo: 42,035 MT Shipment
                    : From Indonesia to Bangladesh Shipment Date: August 24,
                    2016 Specification/Test Report: Please Visit 3. Cement
                    Clinker Vessel Name: MV Franbo Wind Total Cargo: 42,600 MT
                    Shipment : From China to Bangladesh Shipment Date: August
                    13, 2016 Specification/Test Report: Please Visit 4.
                    Limestone Vessel Name: MV Xing Hao Hai Total Cargo: 58,850
                    MT Shipment : From Vietnam to Bangladesh Shipment Date:
                    August 02, 2016 Specification/Test Report: Please Visit 5.
                    Cement Clinker & GBF Slag Vessel Name: MV Theometor Total
                    Cargo: 54,638 MT Shipment : From China to Bangladesh
                    Shipment Date: July 23, 2016 Specification/Test Report: GBF
                    Slag & Clinker 6. Cement Clinker Vessel Name: MV Nord
                    Treasure Total Cargo: 47,585 MT Shipment : From Vietnam to
                    Mozambique Shipment Date: July 21, 2016 Specification/Test
                    Report: Please Visit 7. Gabbro Aggregate (Crushed Stone)
                    Vessel Name: MV Jewel of Sohar Total Cargo: 46,521 MT
                    Shipment : From Oman to Bangladesh Shipment Date: July 07,
                    2016 Specification/Test Report: Please Visit 8. Cement
                    Clinker Vessel Name: MV Golden Resource Total Cargo: 44,890
                    MT Shipment : From China to Bangladesh Shipment Date: May
                    11, 2016 Specification/Test Report: Please Visit 9. Cement
                    Clinker & Limestone Vessel Name: MV Yin Lu Total Cargo:
                    47,600 MT Shipment : From Vietnam to Bangladesh Shipment
                    Date: April 30, 2016 Specification/Test Report: Please Visit
                    - Limestone 10. Cement Clinker & Limestone Vessel Name: MV
                    Hinode Maru Total Cargo: 57,750 MT Shipment : From Vietnam
                    to Bangladesh Shipment Date: April 24, 2016
                    Specification/Test Report: Please Visit -Clinker 11. Cement
                    Clinker Vessel Name: MV Doro Total Cargo: 56,900 MT Shipment
                    : From Vietnam to Bangladesh Shipment Date: April 14, 2016
                    Specification/Test Report: Please Visit 12. Natural Gypsum
                    Vessel Name: MV Umm Ad Dalkh Total Cargo: 33,000 MT Shipment
                    : From Oman to Malaysia Shipment Date: April 01, 2016
                    Specification/Test Report: Please Visit
                  </p>
                ) : newsId === 4 ? (
                  <p>
                    Cement Knowledge Sharing Conference “Cement Knowledge
                    Sharing Conference” organized by Peakward Group, Hong Kong
                    and SUNNSY Cement Group of China is held on October 08, 2015
                    at Royal Park Residence Hotel, Dhaka, Bangladesh.
                  </p>
                ) : newsId === 5 ? (
                  <p>
                    Chairman of Peakward Group Mr. Fazle Azim and Bangladeshi
                    Community living in Vietnam met with Honorable President of
                    Bangladesh Mr. Abdul Hamid at Hanoi of Vietnam on 09 August
                    2015. The visit of Bangladesh president is expected to
                    further deepen the cordial relations and strengthen the ties
                    of friendship and trade and investment relations between
                    Bangladesh and the Southeast Asian country.
                  </p>
                ) : newsId === 6 ? (
                  <p>
                    In a resounding triumph, PEAKWARD GROUP, a renowned industry
                    leader, successfully participated in the highly anticipated
                    INTERCEM DUBAI 2023. This momentous event, held recently,
                    brought together a diverse delegation from Hong Kong, India,
                    UAE, and Turkey, showcasing the company's commitment to
                    global collaboration and innovation. <br />
                    With its unwavering dedication to shaping the future of the
                    industry, PEAKWARD GROUP made a significant impact at
                    INTERCEM DUBAI 2023. The event served as an exceptional
                    platform for industry leaders, experts, and visionaries to
                    exchange groundbreaking ideas, explore cutting-edge
                    technologies, and forge valuable connections. <br />
                    During the event, PEAKWARD GROUP demonstrated its innovative
                    solutions and visionary approach at its exclusive booth,
                    number 23. Showcasing a range of pioneering technologies and
                    methodologies, the company captivated attendees with its
                    commitment to driving progress and propelling sustainable
                    growth. <br />
                    The participation of PEAKWARD GROUP in INTERCEM DUBAI 2023
                    fostered an atmosphere of collaboration and sparked
                    transformative discussions. The company's representatives
                    engaged in fruitful conversations, exchanging insights with
                    industry peers and thought leaders, contributing to the
                    advancement of the global industry landscape. <br />
                    This remarkable event provided PEAKWARD GROUP with a unique
                    opportunity to strengthen existing partnerships and
                    establish new alliances with industry stakeholders
                    worldwide. The connections forged during INTERCEM DUBAI 2023
                    will undoubtedly play a crucial role in driving future
                    growth and fostering innovation within the industry. <br />
                    Reflecting on the success of the event, PEAKWARD GROUP
                    expressed its gratitude to the organizers of INTERCEM DUBAI
                    2023 for hosting a truly exceptional gathering. The company
                    remains committed to its mission of innovation,
                    transformation, and inspiring progress in the industry.
                    <br />
                    As PEAKWARD GROUP concludes its participation in INTERCEM
                    DUBAI 2023, it looks forward to leveraging the knowledge,
                    insights, and connections gained from this prestigious event
                    to further propel its global impact. The company remains
                    dedicated to driving positive change, shaping the industry's
                    future, and delivering innovative solutions that exceed
                    expectations.
                  </p>
                ) : newsId === 7 ? (
                  <p>
                    This glorious morning, resonating with joy and anticipation,
                    our esteemed Peakward Bangladesh family gathered to
                    celebrate the advent of a brand new year. The air was filled
                    with an electric sense of excitement and camaraderie as we
                    eagerly awaited the arrival of a special guest who would
                    grace our event with his esteemed presence.
                    <br />
                    And lo and behold, our hearts swelled with pride as our
                    respected Chairman, Mr. Fazle Azim, entered the premises,
                    exuding an aura of grace and leadership. The room erupted in
                    applause and cheerful greetings, for his presence alone
                    elevated the significance of our New Year Celebration to
                    extraordinary heights.
                    <br />
                    As the celebrations unfolded, we were privileged to witness
                    Mr. Fazle Azim's inspiring words of wisdom, resonating with
                    his unwavering commitment to excellence and innovation. His
                    insightful remarks served as a guiding light, igniting our
                    passion and fueling our collective determination to strive
                    for greatness in the year ahead.
                    <br />
                    Amidst the joyous atmosphere, we indulged in delectable
                    treats and shared heartfelt conversations, strengthening the
                    bonds of camaraderie within our Peakward Bangladesh family.
                    Laughter echoed throughout the office, as memories were
                    created and cherished, forging an everlasting sense of unity
                    and togetherness. <br />
                    The presence of our esteemed Chairman was not only a
                    testament to his unwavering dedication to the growth and
                    success of Peakward Group but also a reflection of his
                    belief in the limitless potential of each member of our
                    organization. His presence infused us with renewed energy
                    and motivation, serving as a reminder of the incredible
                    milestones we have achieved together and the even greater
                    triumphs that lie ahead.
                    <br />
                    On this momentous occasion, we extend our heartfelt
                    gratitude to Mr. Fazle Azim for gracing us with his esteemed
                    presence, and we express our utmost appreciation for his
                    invaluable guidance and leadership. With his vision as our
                    guiding light, we embark on this new year with renewed
                    determination and unwavering commitment to excellence, fully
                    confident that we will surpass all expectations and achieve
                    new heights of success as #PeakwardGroup.
                  </p>
                ) : newsId === 8 ? (
                  <p>
                    It was a truly momentous and joyous day for the entire
                    Peakward Family as we gathered to celebrate the exceptional
                    achievements of our top performers at the Bangladesh Office.
                    In a grand ceremony filled with excitement and anticipation,
                    five outstanding individuals were honored with the
                    prestigious Best Performance Awards, recognizing their
                    dedication, hard work, and remarkable contributions to the
                    growth and success of Peakward Group.
                    <br />
                    As the ceremony unfolded, the atmosphere was electric with
                    pride and admiration for these exceptional individuals. With
                    great pride, our esteemed Group Chairman, Mr. Fazle Azim,
                    personally presented the awards to the deserving recipients,
                    highlighting their exceptional talent, commitment, and
                    unwavering passion for excellence. The room reverberated
                    with applause and cheers, as their accomplishments were
                    celebrated by their peers, colleagues, and mentors.
                    <br />
                    But the celebrations did not end there. In a truly
                    heartwarming gesture of gratitude and appreciation, the
                    Employee Appreciation ceremony took center stage. It was
                    announced with great delight that an Employee Welfare Fund
                    would be established within the Group, dedicated to the
                    well-being and happiness of every Peakwardian. This
                    initiative would provide an annual grant for the welfare of
                    their beloved parents, recognizing the importance of family
                    and honoring the sacrifices made by those who have nurtured
                    and supported our dedicated employees.
                    <br />
                    The announcement of the Employee Welfare Fund further
                    cemented the Peakward Group's commitment to its employees,
                    valuing their well-being, and acknowledging their integral
                    role in the organization's success. This noble endeavor not
                    only demonstrates our dedication to fostering a nurturing
                    work environment but also reflects the deep appreciation we
                    have for our employees' hard work, loyalty, and dedication.
                    <br />
                    The powerful mantra of "INNOVATE . TRANSFORM . INSPIRE"
                    echoed throughout the ceremony, serving as a reminder of our
                    collective mission to drive innovation, embrace
                    transformation, and inspire greatness in everything we do.
                    The celebration of the Best Performance Awards and the
                    establishment of the Employee Welfare Fund showcased the
                    strong bond within the Peakward Family, fostering a culture
                    of recognition, engagement, and well-being.
                    <br />
                    As the hashtags #peakwardgroup #employeeawards
                    #employeeengagement #employeewelfare trended on social
                    media, the spirit of celebration and pride extended beyond
                    the walls of our organization, reaching a wider audience
                    that recognized Peakward Group's commitment to its
                    employees' growth, happiness, and overall well-being. <br />
                    On this extraordinary day, we express our heartfelt
                    congratulations to the deserving recipients of the Best
                    Performance Awards and extend our deepest gratitude to our
                    esteemed Group Chairman, Mr. Fazle Azim, for his visionary
                    leadership and unwavering support. Together, as the Peakward
                    Family, we embrace the spirit of innovation, transformation,
                    and inspiration, confident that our collective efforts will
                    continue to drive us towards unprecedented heights of
                    success and prosperity.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="search-section">
            <div className="search">
              <h3>Search</h3>
              <input type="text" />
            </div>

            <div className="keywords">
              <button>News</button>
              <button>Articles</button>
            </div>
          </div>
        </div>
      </NewsListStyle>
    </>
  );
};

export default NewsDetail;

const NewsListStyle = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5rem;
  padding: 5rem 0;

  .container {
    width: 1300px;
    margin: auto;
    display: flex;
    justify-content: space-between;
  }
  /* 
  .title h1 {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: 2.5rem;
  } */

  .news-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .news-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 2rem;
    padding: 2rem 0;
    max-width: 800px;
    &:first-child {
      padding: 0 0 2rem 0;
    }
  }

  .news-item img {
    /* border-radius: 0.5rem; */
    /* max-width: 250px;
    min-height: 140px; */
    width: 100%;
  }

  .text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.5rem;
  }

  .date {
    /* color: #007aff; */
    color: ${(props) => props.theme.colors.caption};
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 700;
  }

  .title {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 600;
    line-height: 2rem;
  }
  .description {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 400;
  }

  .links {
    padding-left: 4rem;
  }

  /* .links button {
    border: none;
    color: ${(props) => props.theme.colors.highlight};
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    background: none;
    cursor: pointer;
  } */

  .search-section {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .keywords {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .search h3 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.h6};
  }

  .search {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .search input {
    border-radius: 0.5rem;
    height: 2rem;
    width: 250px;
    font-size: ${(props) => props.theme.fontSizes.p};
    padding: 1.2rem 1rem;
    font-family: "Open Sans", sans-serif;
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  .keywords button {
    background-color: ${(props) => props.theme.colors.primary};
    padding: 0.8rem 1.5rem;
    border-radius: 1.5rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.default};
    border: none;
    width: fit-content;
    min-width: 100px;
    font-size: ${(props) => props.theme.fontSizes.p};
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
    }
  }

  @media only screen and (max-width: 600px) {
    .container {
      flex-direction: column-reverse;
      width: 90%;
      row-gap: 5rem;
    }

    /* .title h1 {
      font-size: 2rem;
    } */
    .news-item {
      flex-direction: column;
      /* row-gap: 1rem; */
    }

    .text {
      display: flex;
      flex-direction: column;
      /* row-gap: 1rem; */
      min-width: unset;
      align-items: center;
      text-align: center;
    }

    .title {
      font-size: ${(props) => props.theme.fontSizes.h6};
    }

    .news-section {
      align-items: center;
    }

    /* .links {
      padding-left: unset;
    } */
    .description p {
      text-align: center;
    }

    .search,
    .keywords {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
