import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import styled from "styled-components";

const ProductBanner = (props) => {
  const [quoteModal, setQuoteModal] = useState(false);
  return (
    <ProductBannerStyle bgImage={props.bgImage}>
      <div className="container">
        <h1>{props.title}</h1>
        <p>{props.des}</p>
        <button onClick={() => setQuoteModal(true)}>Get a quote?</button>
      </div>
      {quoteModal && <div className="overlay"></div>}

      {quoteModal && (
        <Modal className="modal">
          <div className="container">
            <div className="close">
              <button onClick={() => setQuoteModal(false)}>
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </div>
            <div className="title">
              <h1>Schedule a Meeting</h1>
            </div>
            <div className="form">
              <div className="left">
                <div className="email">
                  <h1>Email</h1>
                  <input type="email" />
                </div>
                <div className="phone">
                  <h1>Phone</h1>
                  <input type="text" />
                </div>
              </div>
              <div className="right">
                <div>
                  <h1>Message</h1>
                  <textarea rows={7} cols={45} />
                </div>

                <button>Submit</button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </ProductBannerStyle>
  );
};

export default ProductBanner;

const ProductBannerStyle = styled.div`
  min-height: 110vh;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  display: flex;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.colors.default};
    width: 50%;
    margin: auto;
    row-gap: 2rem;
    text-align: justify;
  }

  .overlay {
    background-color: black;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 100;
    opacity: 0.5;
  }

  .container h1 {
    font-family: "Josefin Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h2};
    padding-bottom: 4rem;
  }

  .container p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    font-weight: 300;
  }

  .container button {
    font-family: "Open Sans", sans-serif;
    background-color: transparent;
    color: ${(props) => props.theme.colors.default};
    border: 1px solid ${(props) => props.theme.colors.default};
    border-radius: 1.5rem;
    padding: 0.8rem 1.5rem;
    font-size: ${(props) => props.theme.fontSizes.p};

    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${(props) => props.theme.colors.btnHover};
    }
  }
  @media only screen and (max-width: 1400px) {
    .container {
      padding-top: 8rem;
      padding-bottom: 5rem;
      width: 80%;
    }
  }

  @media only screen and (max-width: 600px) {
    background-size: cover;
    background-position: center;
    .container {
      width: 90%;
      padding-top: 10rem;
      padding-bottom: 5rem;
    }
    .container h1 {
      font-size: ${(props) => props.theme.fontSizes.h4};
      line-height: ${(props) => props.theme.lineHeights.h4};
      text-align: center;
    }

    .container p {
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 100;
  background-color: var(--secondary);
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  width: 50%;
  align-items: center;
  right: 0;
  left: 0;
  height: fit-content;
  padding: 3rem 4rem;

  .close {
    /* width: 90%; */
    /* border: 1px solid black; */
    text-align: right;
    /* padding: 1rem; */
    position: absolute;
    top: 0;
    right: 0;
  }

  .container {
    /* min-width: 600px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0;
    width: 100%;
    position: relative;
  }

  .title h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    column-gap: 10rem;
    /* min-width: 800px; */
    max-width: 800px;
  }

  .left {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }
  .right {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .form h1 {
    font-family: "Josefin Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.h6};
    line-height: ${(props) => props.theme.lineHeights.h6};
    padding-bottom: 0.4rem;
    text-align: left;
  }

  .right h1 {
    padding-bottom: 0.5rem;
    text-align: left;
  }

  input,
  textarea {
    border: none;
    padding: 1rem;
    font-size: ${(props) => props.theme.fontSizes.p};
    border: 1px solid ${(props) => props.theme.colors.primary};
    border-radius: 0.5rem;
    font-family: "Open Sans", sans-serif;
    width: 100%;
  }

  .form button {
    width: fit-content;
    color: ${(props) => props.theme.colors.default};
    font-family: "Open Sans", sans-serif;
    background: ${(props) => props.theme.colors.primary};
    border: none;
    padding: 1rem 3.5rem;
    font-weight: 700;
    font-size: ${(props) => props.theme.fontSizes.p};
    cursor: pointer;
    align-self: center;
  }

  .close button {
    width: fit-content;
    padding: 0;
    background-color: transparent;
    color: ${(props) => props.theme.colors.primary};
    border: none;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
  }

  .close button svg {
    font-size: ${(props) => props.theme.fontSizes.xl};
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    max-width: unset;
    min-width: unset;
    padding: 1.5rem;
    .container {
      width: 100%;

      min-width: unset;
      /* margin-top: -50px; */
    }
    .form {
      flex-direction: column;
      row-gap: 1rem;
      min-width: unset;
    }

    .left,
    .right {
      row-gap: 1rem;
    }

    .title h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
    }

    .form h1 {
      font-size: ${(props) => props.theme.fontSizes.p};
      line-height: ${(props) => props.theme.lineHeights.p};
    }

    input {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      padding: 10px;
    }
    textarea {
      width: 100%;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      padding: 10px;
    }

    .close {
      top: 5%;
    }
  }
`;
