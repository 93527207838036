import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import Logo from "../assets/img/footer/logo.svg";
import Facebook from "../assets/img/footer/facebook.svg";
import FacebookWhite from "../assets/img/footer/facebook-white.svg";
import Linkedin from "../assets/img/footer/linkedIn.svg";
import LinkedinWhite from "../assets/img/footer/linkedIn-white.svg";
import Twitter from "../assets/img/footer/twitter.svg";
import TwitterWhite from "../assets/img/footer/twitter-white.svg";
import flag1 from "../assets/img/contact/contact1.png";
import flag2 from "../assets/img/contact/contact2.png";
import flag3 from "../assets/img/contact/contact4.png";
import flag4 from "../assets/img/contact/contact5.png";
import flag5 from "../assets/img/contact/contact6.png";
import flag6 from "../assets/img/contact/contact7.png";
import flag7 from "../assets/img/contact/contact8.png";
import flag8 from "../assets/img/contact/contact9.png";

const Footer = () => {
  const [facebookIcon, setFacebookIcon] = useState(true);
  const [linkedinIcon, setLinkedinIcon] = useState(true);
  const [twitterIcon, setTwitterIcon] = useState(true);
  return (
    <FooterStyle>
      <div className="container">
        <div className="flags">
          <img src={flag1} alt="flag" />
          <img src={flag2} alt="flag" />
          <img src={flag3} alt="flag" />
          <img src={flag4} alt="flag" />
          <img src={flag5} alt="flag" />
          <img src={flag6} alt="flag" />
          <img src={flag7} alt="flag" />
          <img src={flag8} alt="flag" />
        </div>
        <div className="top">
          <div className="contact">
            <div className="pages">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              {/* <Link to="/">Go Green</Link> */}
              <Link to="/clinker">Clinker</Link>
              <Link to="/limestone">Limestone</Link>
              <Link to="/gypsum">Gypsum</Link>
              <Link to="/pozzolana">Pozzolana</Link>
              <Link to="/slag">Slag</Link>
              <Link to="/construction_stone">Construction Stone</Link>
              <Link to="/trading">Trading</Link>
              <Link to="/shipping">Shipping</Link>
              <Link to="/news">News and Events</Link>
              <Link to="/contact">Contact Us</Link>
              <div className="social">
                <Link
                  to="https://www.facebook.com/peakward/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {facebookIcon ? (
                    <img
                      onMouseEnter={() => setFacebookIcon(false)}
                      src={Facebook}
                      alt="social link"
                    />
                  ) : (
                    <img
                      onMouseLeave={() => setFacebookIcon(true)}
                      src={FacebookWhite}
                      alt="social link"
                    />
                  )}
                </Link>
                <Link
                  to="https://www.linkedin.com/company/peakward/?originalSubdomain=hk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {linkedinIcon ? (
                    <img
                      onMouseEnter={() => setLinkedinIcon(false)}
                      src={Linkedin}
                      alt="social link"
                    />
                  ) : (
                    <img
                      onMouseLeave={() => setLinkedinIcon(true)}
                      src={LinkedinWhite}
                      alt="social link"
                    />
                  )}
                </Link>

                <Link
                  to="https://twitter.com/peakward?lang=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {twitterIcon ? (
                    <img
                      onMouseEnter={() => setTwitterIcon(false)}
                      src={Twitter}
                      alt="social link"
                    />
                  ) : (
                    <img
                      onMouseLeave={() => setTwitterIcon(true)}
                      src={TwitterWhite}
                      alt="social link"
                    />
                  )}
                </Link>
              </div>
              <div className="address">
                <p>info@peakward.com</p>
                <Link to="tel:5551234567">
                  <p>(880-2) 2222 75371</p>
                </Link>
              </div>
            </div>
            {/* <div className="subscribe">
              <h1>Subscribe Newsletter</h1>
              <div className="mail">
                <p>
                  By subscribing to our mailing list you <br /> will alwyas be
                  updated
                </p>
                <div className="input">
                  <input type="email" placeholder="Your Email " />
                  <button>Subscribe</button>
                </div>
              </div>
            </div> */}
            <img src={Logo} alt="logo" />
          </div>
          <div className="contacts">
            <div className="country">
              <h4>Peakward Enterprises (Holdings) LTD.</h4>
              <h5>(Group Head Office)</h5>
              <p>
                2901 Pacific Plaza, 29th Floor 410 Das Voeux Road West, Hong
                Kong.
              </p>
              <p>Tel: (852) 2857 6126</p>
              {/* <p>Fax: (852) 2857 6122</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARD BANGLASESH LTD.</h4>
              <h5>(BANGLADESH)</h5>
              <p>
                Ahmed Tower (7th floor), 28-30, Kemal Ataturk Avenue, Banani,
                Dhaka-1213, Bangladesh.
              </p>
              <p>Tel: (880-2) 7112263, 7122141, 7112295</p>
              {/* <p>Fax: (880-2) 9565109</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARDS ENTERPRISE (BEIJING) COMPANY LTD.</h4>
              <h5>(CHINA)</h5>
              <p>
                Suit 1701-1703 17/F, Huantengbeitang, Business Building. No 37,
                South Grinding Mill Road. Chaoyang District, Beijing, PRC.
              </p>
              <p>Tel: (0) 86-10-51909139 Ext: 17806</p>
              {/* <p>Fax: (0) 86-10-51909120</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARDS DENIZICLIK VE TICARET LIMITED STI.</h4>
              <h5>(TURKEY)</h5>
              <p>
                Konaklar Mah, Selvil Sok. 13 Blok A Kapisi No: 12/B Kat: 2
                Daire: 34.Levent, 34330 Istanbul/Turkey.
              </p>
              <p>Tel: (0) 86-10-51909139 Ext: 17806</p>
              <p>Tel: +90 212 282 42 30 (31-32 PBX)</p>
              {/* <p>Fax: +90 212 282 42 33</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARD ENTERPRISES INDIA PVT LTD</h4>
              <h5>(INDIA)</h5>
              <p>
                2044/6 Chitragupta Road, Chunamandi, Paharganj, New Delhi -
                110055
              </p>
              <p>Mobile : +91 9873000756</p>
              {/* <p>Email: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARDS VIETNAM COMPANY LTD.</h4>
              <h5>(VIETNAM)</h5>
              <p>
                Suit 1104, VIGLACERA Tower. No. 01 Thang Long Road. Nam Tu Liem
                District Hanoi, Vietnam.
              </p>
              <p>Tel: (84-4) 38580235/36</p>
              {/* <p>Fax: (84-4) 37955285</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PT. PEAKWARD INDONESIA</h4>
              <h5>(INDONESIA)</h5>
              <p>
                Menara Global 20th Floor. JI, Gatot Subroto Kav, 27, Setiabudi.
                Kuninan Timur, Jakarta, Indonesia
              </p>
              <p>Mobile: +628117228895, +628126727075</p>
              {/* <p>Fax: (84-4) 37955285</p>
              <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            <div className="country">
              <h4>PEAKWARD ENTERPRISE FZE</h4>
              <h5>(UAE)</h5>
              <p>P.O. Box 10055, Ras Al Khaimah, United Arab Emirates</p>
              <p>Tel: 971-7-2077775</p>
              {/* <p>Website: www.peakward.com</p>
              <p>E-mail: info@peakward.com</p> */}
            </div>

            {/* <div className="subscribe">
              <h1>Subscribe Newsletter</h1>
              <div className="mail">
                <p>
                  By subscribing to our mailing list you <br /> will alwyas be
                  updated
                </p>
                <div className="input">
                  <input type="email" placeholder="Your Email " />
                  <button>Subscribe</button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="bottomContainer">
          <p>PEAKWARD ENTERPRISES (HOLDINGS) LIMITED.</p>
          <p>Copyright &copy; 2023 all rights reserved.</p>
        </div>
      </div>
    </FooterStyle>
  );
};

export default Footer;

const FooterStyle = styled.footer`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.default};

  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 0;
  }

  .flags {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    /* align-items: center; */
    padding-bottom: 2rem;
    margin-bottom: 4rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
  .flags img {
    max-height: 50px;
    /* height: 110px; */
  }

  .top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 2rem;
    min-width: 300px;
  }

  .contact a {
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
    font-weight: 500;
    &:hover {
      color: ${(props) => props.theme.colors.highlight};
    }
  }

  .contact > img:first-child {
    width: 150px;
    padding-bottom: 2rem;
  }

  .social {
    display: flex;
    column-gap: 10px;
    margin-top: 1rem;
  }

  .social img {
    width: 35px;
  }

  .address {
    font-family: "Josefin Sans", sans-serif;
    display: flex;
    flex-direction: column;
  }

  .address p {
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
  }

  .address a {
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
  }

  .contacts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    font-family: "Open Sans", sans-serif;
    justify-content: space-between;
    width: 100%;
    gap: 2.5rem;
  }

  .country {
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    max-width: 268px;
  }

  .country p {
    font-weight: 300;
  }

  .country h4,
  .country h5 {
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
  }

  .pages {
    display: flex;
    flex-direction: column;
  }

  a,
  .subscribe p {
    font-family: "Open Sans", sans-serif;
    font-size: ${(props) => props.theme.fontSizes.small};
    line-height: ${(props) => props.theme.lineHeights.small};
    font-weight: 300;
    color: ${(props) => props.theme.colors.default};
    text-decoration: none;
  }

  .about a,
  .products a {
    width: fit-content;
    position: relative;
    font-size: ${(props) => props.theme.fontSizes.small};
    &::after {
      content: "";
      display: block;
      height: 3px;
      background-color: ${(props) => props.theme.colors.default};
      position: absolute;
      bottom: -5px;
      width: 0%;
      transition: all 0.2s ease-in-out;
    }
    &:hover::after {
      width: 100%;
    }
  }

  .mail {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }

  .mail p:first-of-type {
    line-height: ${(props) => props.theme.lineHeights.small};
  }

  .input {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    font-size: ${(props) => props.theme.fontSizes.small};
  }

  .input input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
  }

  .input button {
    width: fit-content;
    padding: 0.6rem;
    border-radius: 5px;
    border: none;
    background-color: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.default};
    font-size: ${(props) => props.theme.fontSizes.small};
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
      background-color: ${(props) => props.theme.colors.default};
      color: ${(props) => props.theme.colors.black};
      transition: all 0.2s ease-in-out;
    }
  }

  .bottom {
    border-top: 1px solid ${(props) => props.theme.colors.border};
    font-family: "Open Sans", sans-serif;
    padding: 1rem 0;
  }

  .bottomContainer {
    display: flex;
    justify-content: space-between;
    max-width: 1600px;
    margin: auto;
    padding: 0.5rem 0;
  }

  .bottom p:first-of-type {
    text-transform: uppercase;
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 400;
  }

  .bottom p:last-of-type {
    font-size: ${(props) => props.theme.fontSizes.small};
    font-weight: 300;
  }

  @media only screen and (max-width: 1600px) {
    .container {
      max-width: 1200px;
      /* padding: 3rem 2rem; */
    }

    .bottomContainer {
      max-width: 1200px;
      /* padding: 1rem 2rem; */
    }
  }

  @media only screen and (max-width: 600px) {
    .flags img {
      max-height: 35px;
    }
    .top {
      flex-direction: column;
      row-gap: 2rem;
    }
    .container {
      width: 100%;
      padding: 4rem 1rem;
    }

    .contact {
      flex-direction: column-reverse;
    }

    .contacts {
      grid-template-columns: 1fr;
    }
    .bottomContainer {
      flex-direction: column;
      padding: 0.5rem 1rem;
      row-gap: 5px;
    }

    .bottom p:first-of-type {
      text-align: center;
    }
    .bottom p:last-of-type {
      text-align: center;
    }
  }
`;
