import { Link } from "react-router-dom";
import styled from "styled-components";
import aboutSvg from "../assets/img/about.svg";
import Lottie from "lottie-react";
import playbuttonAnimation from "../assets/animation/play.json";
import closeIcon from "../assets/img/modal-close.svg";
import { useState } from "react";

const AboutIntro = () => {
  const [modal, setModal] = useState(false);
  return (
    <AboutIntroStyle>
      {modal && (
        <>
          <div className="overlay"></div>
          <div className="modal">
            <img
              onClick={() => setModal(false)}
              src={closeIcon}
              alt="close icon"
            />

            <iframe
              // width="760"
              // height="415"
              src="https://www.youtube.com/embed/5XpXNIcT0tQ?autoplay=1&mute=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </div>
        </>
      )}

      <div className="container">
        <div className="img">
          <img src={aboutSvg} alt="icons" />

          <Lottie
            onClick={() => setModal(true)}
            id="playButton"
            animationData={playbuttonAnimation}
            style={{ cursor: "pointer" }}
          />
        </div>
        <article>
          <p>WHO WE ARE</p>
          <h1>
            Adding value to the global trading industry for over 3 Decades
          </h1>
          <div className="img-mobile">
            <img src={aboutSvg} alt="icons" />

            <Lottie
              onClick={() => setModal(true)}
              id="playButton"
              animationData={playbuttonAnimation}
            />
          </div>
          <p className="details">
            Peakward Group is a well-regarded multinational conglomerate with a
            robust 33-year history in the global business scene. Renowned for
            its reliable and comprehensive supply of bulk commodities,
            Peakward's reputation rests on a foundation of excellence and
            innovative supply chain solutions. With its headquarters in Hong
            Kong and a significant presence in 8 countries, the Group plays a
            pioneering role in diverse markets. Seamlessly merging the sourcing
            of raw materials with international logistics, Peakward Group stands
            as a pillar supporting the building and construction industry. Our
            steadfast commitment to empowering global infrastructure and driving
            sustainable growth exemplifies the forward-thinking ethos we hold,
            shaping today and future generations to come.
          </p>
          <Link to="/about">
            <button>Learn More</button>
          </Link>
        </article>
      </div>
    </AboutIntroStyle>
  );
};

export default AboutIntro;

const AboutIntroStyle = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${(props) => props.theme.colors.overlay};
    z-index: 10;
  }

  .modal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 20;

    background-color: ${(props) => props.theme.colors.default};

    width: 60%;
    height: 60%;
    margin: auto;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.5rem 4.5rem 0.5rem;

    iframe {
      width: 100%;
      height: 100%;
    }

    img {
      position: absolute;
      bottom: 5px;
      right: 0;
      left: 0;
      margin: 0 auto;
      cursor: pointer;
      background-color: #000000;
      border-radius: 50px;
      padding: 5px;
    }
  }

  .container {
    max-width: 1600px;
    margin: auto;
    display: flex;
    /* column-gap: 8rem; */
    align-items: center;
    justify-content: space-between;
    /* min-height: 100vh; */
    padding: 5rem 0;
    padding-top: 10rem;
  }

  .img {
    position: relative;
  }

  .img img {
    width: 100%;
    min-width: 600px;
  }

  #playButton {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 180px;
  }

  article {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    font-family: "Open Sans", sans-serif;
    color: ${(props) => props.theme.colors.primary};
    width: 50%;
  }

  .details {
    line-height: 2rem;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.fontSizes.p};
    line-height: ${(props) => props.theme.lineHeights.p};
  }

  article p:first-child,
  article h1 {
    font-family: "Josefin Sans", sans-serif;
  }

  article h1 {
    font-size: ${(props) => props.theme.fontSizes.h3};
    line-height: ${(props) => props.theme.lineHeights.h3};
  }

  article p:first-child {
    color: ${(props) => props.theme.colors.highlight};
    font-size: ${(props) => props.theme.fontSizes.h5};
    line-height: ${(props) => props.theme.lineHeights.h5};
    font-weight: 600;
  }

  article button {
    color: ${(props) => props.theme.colors.primary};
    font-family: "Open Sans", sans-serif;
    background: transparent;
    width: fit-content;
    border: 1px solid #0d1856;
    padding: 0.5rem 1.5rem;
    border-radius: 1rem;
    font-weight: 800;
    font-size: ${(props) => props.theme.fontSizes.p};
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background-color: #37487a;
      color: ${(props) => props.theme.colors.default};
      transition: 0.3s ease;
      border-color: ${(props) => props.theme.colors.default};
    }
  }

  .img-mobile {
    display: none;
    position: relative;
  }

  @media only screen and (max-width: 1600px) {
    .modal {
      width: 80%;
      height: 70%;
      padding: 2rem;
    }
    .container {
      max-width: 1200px;
      /* padding-left: 2rem;
      padding-right: 2rem; */
    }
    .img img {
      min-width: 100%;
      max-width: 450px;
    }
    article {
      width: 55%;
    }
  }

  @media only screen and (max-width: 600px) {
    margin-top: -35px;
    .modal {
      width: 95%;
      height: fit-content;
      /* padding: unset; */
      padding: 0;

      iframe {
        width: 100%;
        height: 380px;
      }

      /* img {
        position: absolute;
         top: 0px;
        right: 20px; 
        cursor: pointer;
      } */
    }

    article {
      width: 100%;
      padding-top: 50px;
    }

    .container {
      max-width: 1600px;
      margin: auto;
      display: flex;
      flex-direction: column;
      padding: 5rem 1rem;
      text-align: center;
      align-items: center;
      row-gap: 3rem;
      margin-top: 2rem;
    }

    article {
      align-items: center;
    }

    article p:first-child {
      font-weight: 600;
      font-size: ${(props) => props.theme.fontSizes.h6};
      line-height: ${(props) => props.theme.lineHeights.h6};
    }
    .details {
      text-align: center;
      font-size: ${(props) => props.theme.fontSizes.small};
      line-height: ${(props) => props.theme.lineHeights.small};
      padding: 0 1rem;
    }

    article h1 {
      font-size: ${(props) => props.theme.fontSizes.h5};
      line-height: ${(props) => props.theme.lineHeights.h5};
      /* text-align: justify; */
    }
    .img-mobile {
      display: block;
      padding: 1rem 0;
    }

    #playButton {
      width: 120px;
    }

    .img-mobile img {
      width: 300px;
    }

    .img {
      display: none;
    }
  }
`;
